import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /**
   * The `Time` scalar type represents a time. The Time appears in a JSON
   * response as an ISO8601 formatted string, without a date component.
   */
  Time: any;
};

export type Agency = {
  __typename?: 'Agency';
  history: Scalars['String'];
  id: Scalars['ID'];
  logo: Asset;
  name: Scalars['String'];
};

export enum AgentBadge {
  Bronze = 'BRONZE',
  Diamond = 'DIAMOND',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
  Silver = 'SILVER',
  Verified = 'VERIFIED'
}

export type AgentCommission = {
  __typename?: 'AgentCommission';
  commissionType: AgentCommissionCommissionType;
  flatFeeCostDollars?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  percentageRate?: Maybe<Scalars['Float']>;
};

export enum AgentCommissionCommissionType {
  FlatFee = 'FLAT_FEE',
  Percentage = 'PERCENTAGE'
}

export type AgentMarketingFee = {
  __typename?: 'AgentMarketingFee';
  costDollars: Scalars['Float'];
  id: Scalars['ID'];
  marketingInclusions: Array<Scalars['String']>;
};

export type AgentProfile = {
  __typename?: 'AgentProfile';
  agencyBio?: Maybe<Scalars['String']>;
  agencyLogo?: Maybe<UserMedia>;
  agencyName?: Maybe<Scalars['String']>;
  badge?: Maybe<AgentBadge>;
  bio?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  numberOfEngagements: Scalars['Int'];
  profilePicture?: Maybe<UserMedia>;
};

export type AgentSuburbSubscriptionFreeTrialState = {
  __typename?: 'AgentSuburbSubscriptionFreeTrialState';
  /** The number of free trial suburb subscriptions that this agent had claimed. */
  numberOfFreeTrialsClaimed: Scalars['Int'];
  /** The max number of free trial suburb subscriptions that this agent can claim. */
  totalFreeTrialsAllowed: Scalars['Int'];
};

export type AppointmentRequest = {
  __typename?: 'AppointmentRequest';
  id: Scalars['ID'];
  property: Property;
  requestedAppointmentDate: Scalars['Date'];
  requestedAppointmentTime: Scalars['Time'];
  requestedBySellerAt: Scalars['DateTime'];
  seenByAgent: Scalars['Boolean'];
  sellerEmail?: Maybe<Scalars['String']>;
  sellerMobileNumber?: Maybe<Scalars['String']>;
  sellerName?: Maybe<Scalars['String']>;
};

export type AppointmentRequestConnection = {
  __typename?: 'AppointmentRequestConnection';
  edges?: Maybe<Array<Maybe<AppointmentRequestEdge>>>;
  pageInfo: PageInfo;
};

export type AppointmentRequestEdge = {
  __typename?: 'AppointmentRequestEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AppointmentRequest>;
};

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};



/** A draft property that has not been sent to any agents for appraisal */
export type DraftProperty = {
  __typename?: 'DraftProperty';
  additionalFeatures?: Maybe<Array<PropertyAdditionalFeature>>;
  additionalFeaturesQuestionRequirement: PropertyQuestionRequirement;
  additionalInformation?: Maybe<Scalars['String']>;
  estimatedSaleValue?: Maybe<Scalars['Int']>;
  estimatedSaleValueRange?: Maybe<PropertyEstimatedSaleValueRange>;
  floorplans: Array<PropertyMedia>;
  id: Scalars['ID'];
  idealTimeframeOfSale?: Maybe<PropertyIdealTimeframeOfSale>;
  landSizeSqm?: Maybe<Scalars['Int']>;
  landSizeSqmQuestionRequirement: PropertyQuestionRequirement;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBathroomsQuestionRequirement: PropertyQuestionRequirement;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfBedroomsQuestionRequirement: PropertyQuestionRequirement;
  numberOfCarSpaces?: Maybe<Scalars['Int']>;
  numberOfCarSpacesQuestionRequirement: PropertyQuestionRequirement;
  numberOfLivingSpaces?: Maybe<Scalars['Int']>;
  numberOfLivingSpacesQuestionRequirement: PropertyQuestionRequirement;
  numberOfToilets?: Maybe<Scalars['Int']>;
  numberOfToiletsQuestionRequirement: PropertyQuestionRequirement;
  photos: Array<PropertyMedia>;
  preferredMethodOfSale?: Maybe<PropertyPreferredMethodOfSale>;
  propertyAge?: Maybe<Scalars['Int']>;
  propertyAgeQuestionRequirement: PropertyQuestionRequirement;
  propertySizeSqm?: Maybe<Scalars['Int']>;
  propertySizeSqmQuestionRequirement: PropertyQuestionRequirement;
  propertyType: PropertyType;
  state: PropertyState;
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  suburb: Suburb;
  videos: Array<PropertyMedia>;
};

export type DraftPropertyAppraisal = {
  __typename?: 'DraftPropertyAppraisal';
  additionalNotes?: Maybe<Scalars['String']>;
  appraisalVideo?: Maybe<UserMedia>;
  commission?: Maybe<AgentCommission>;
  comparableSales: Array<PropertyAppraisalComparableSale>;
  id: Scalars['ID'];
  marketingFee?: Maybe<AgentMarketingFee>;
  property: Property;
  sellingRangeMax?: Maybe<Scalars['Int']>;
  sellingRangeMin?: Maybe<Scalars['Int']>;
  state: PropertyAppraisalState;
};

export type DraftPropertyConnection = {
  __typename?: 'DraftPropertyConnection';
  edges?: Maybe<Array<Maybe<DraftPropertyEdge>>>;
  pageInfo: PageInfo;
};

export type DraftPropertyEdge = {
  __typename?: 'DraftPropertyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<DraftProperty>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Accept a condition for payment confirmation */
  acceptPaymentCondition: PropertyAppraisalRequest;
  /** Marks an appointment request as having been seen by the agent */
  appointmentRequestSeenByAgent: AppointmentRequest;
  /** Creates a new property media for the draft property using the provided user media */
  attachUserMediaToDraftProperty: DraftProperty;
  /** Prevents a suburb from renewing after it's expiry date. This will either cancel the subscription entirely or adjust the suburb quantity of the existing subscription. Expected errors: not_found */
  cancelSuburbSubscription: SubscribedSuburb;
  /** Confirms the property was sold by agent, this triggers billing of the agent and subsequently cashback payment to the seller */
  confirmPropertySale: PropertySale;
  /** Creates an agent user with a blank profile */
  createAgentUser: Session;
  /** Creates an empty 'ghost' user that can be used to create a draft property listing */
  createGhostUser: Session;
  /** Create a new User Media with presigned upload URLs */
  createUserMedia: NewUserMedia;
  /** Gets the user info from continue email token */
  getUserByToken: Session;
  /** Creates an authorization bearer token for a user */
  login: Session;
  /** Marks a property appraisal request as seen by agent */
  propertyAppraisalRequestSeenByAgent: PropertyAppraisalRequest;
  /** Marks a property appraisal as having been seen by the seller */
  propertyAppraisalSeenBySeller: PropertyAppraisal;
  /** Marks a property as complete but not sold through agentspot */
  propertyCompletedWithoutAgentSpot: Property;
  /** Marks a property as sold through agentspot, this cannot be undone */
  propertySoldThroughAgentSpot: Property;
  /** Publishes a draft property if all required questions have been answered */
  publishProperty: Property;
  /** Removes the property media from a draft property */
  removePropertyMediaFromDraftProperty: DraftProperty;
  /** Creates a new subscription for this suburb, beginning when it's current subscription ends.  Expected errors: not_found */
  renewSuburbSubscription: SubscribedSuburb;
  /** Sends an appointment request to the agent */
  requestAppointmentForAgentFromPropertyAppraisal: PropertyAppraisal;
  /** Resend verification email */
  resendVerificationEmail: User;
  /** Gets the user info from continue email token */
  sendContinueEmail?: Maybe<User>;
  /** Sends a password reset email to the provided email address. Expected errors: invalid_credentials (email not found) */
  sendPasswordResetEmail: Scalars['String'];
  /** Starts a new draft property listing */
  startNewDraftProperty: DraftProperty;
  /** Creates a new draft property appraisal for a property appraisal request, if no property appraisal already exists for this property for this agent */
  startNewDraftPropertyAppraisal: PropertyAppraisalRequest;
  stripeConfirmPaymentMethod: PaymentMethod;
  stripeCreateSetupIntent: StripeSetupIntent;
  /** Submits a draft property appraisal */
  submitPropertyAppraisal: PropertyAppraisalRequest;
  /** Subscribes the agent to suburbs. The agent will become visible to sellers in these suburbs. The agent will be given a free trial for these suburbs immediately and which will renew on a recurring annual basis. Expected errors: payment_declined, some_suburb_at_capacity, some_suburb_already_subscribed_to, some_suburb_not_found, duplicate_suburbs, suburbs_ids_cannot_be_empty, user_missing_stripe_customer, agents_profile_is_incomplete, attempting_to_claim_too_many_suburb_subscription_free_trials */
  subscribeToSuburbs: Array<SubscribedSuburb>;
  /** Updates the name, phone number, agency name and agency logo of the current agent's profile */
  updateAgentProfileBasicDetails: User;
  /** Updates the name and phone number current agent's profile */
  updateAgentProfileDetails: AgentProfile;
  /** Updates the additional notes for a draft property appraisal */
  updateDraftPropertyAppraisalAdditionalNotes: DraftPropertyAppraisal;
  /** Updates or creates the commission for a draft property appraisal */
  updateDraftPropertyAppraisalCommission: DraftPropertyAppraisal;
  /** Updates the comparable sales for a draft property appraisal */
  updateDraftPropertyAppraisalComparableSales: DraftPropertyAppraisal;
  /** Updates or creates the marketing fee for a draft property appraisal */
  updateDraftPropertyAppraisalMarketingFee: DraftPropertyAppraisal;
  /** Updates the selling range of a draft property appraisal */
  updateDraftPropertyAppraisalSellingRange: DraftPropertyAppraisal;
  /** Updates the selling range of a draft property appraisal */
  updateDraftPropertyAppraisalVideo: DraftPropertyAppraisal;
  /** Changes the property details of a draft property listing */
  updatePropertyDetails: DraftProperty;
  /** Changes the sale information of a draft property listing */
  updatePropertySaleInformation: DraftProperty;
  /** Changes the property type of a draft property listing */
  updatePropertyType: DraftProperty;
  /** Updates the name, phone number and (optionally) email of the current user */
  updateUserDetails: User;
  /** Updates the password of the current user. Expected errors: password_too_short */
  updateUserPassword: User;
  /** Updates the users password using the provided password reset token for authentication. Expected errors: invalid_password_reset_token, password_too_short */
  updateUserPasswordWithResetToken: User;
  /** Updates the email verification status of user matching the provided email verification token */
  verifyEmail: User;
};


export type RootMutationTypeAcceptPaymentConditionArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeAppointmentRequestSeenByAgentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeAttachUserMediaToDraftPropertyArgs = {
  propertyId: Scalars['ID'];
  propertyMediaType: PropertyMediaType;
  userMediaId: Scalars['ID'];
};


export type RootMutationTypeCancelSuburbSubscriptionArgs = {
  suburbSubscriptionId: Scalars['ID'];
};


export type RootMutationTypeConfirmPropertySaleArgs = {
  contractDate: Scalars['Date'];
  id: Scalars['ID'];
  salePrice: Scalars['Int'];
};


export type RootMutationTypeCreateAgentUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeCreateUserMediaArgs = {
  fileExtension: Scalars['String'];
  mimeType: Scalars['String'];
};


export type RootMutationTypeGetUserByTokenArgs = {
  continueToken: Scalars['String'];
};


export type RootMutationTypeLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypePropertyAppraisalRequestSeenByAgentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypePropertyAppraisalSeenBySellerArgs = {
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypePropertyCompletedWithoutAgentSpotArgs = {
  completeType: PropertyCompleteTypeWithoutAgentspot;
  propertyId: Scalars['ID'];
};


export type RootMutationTypePropertySoldThroughAgentSpotArgs = {
  accountNumber: Scalars['String'];
  bsb: Scalars['String'];
  propertyId: Scalars['ID'];
  sellingAgentId: Scalars['ID'];
};


export type RootMutationTypePublishPropertyArgs = {
  askAgentSpotForMoreAgents: Scalars['Boolean'];
  propertyId: Scalars['ID'];
  selectedAgents: Array<Scalars['ID']>;
};


export type RootMutationTypeRemovePropertyMediaFromDraftPropertyArgs = {
  propertyMediaId: Scalars['ID'];
};


export type RootMutationTypeRenewSuburbSubscriptionArgs = {
  suburbSubscriptionId: Scalars['ID'];
};


export type RootMutationTypeRequestAppointmentForAgentFromPropertyAppraisalArgs = {
  appointmentDate: Scalars['Date'];
  appointmentTime: Scalars['Time'];
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypeResendVerificationEmailArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type RootMutationTypeSendContinueEmailArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeStartNewDraftPropertyArgs = {
  propertyType: PropertyType;
  streetName: Scalars['String'];
  suburbId: Scalars['ID'];
};


export type RootMutationTypeStartNewDraftPropertyAppraisalArgs = {
  propertyAppraisalRequestId: Scalars['ID'];
};


export type RootMutationTypeStripeConfirmPaymentMethodArgs = {
  stripePaymentMethodId: Scalars['String'];
};


export type RootMutationTypeSubmitPropertyAppraisalArgs = {
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypeSubscribeToSuburbsArgs = {
  suburbIds: Array<Scalars['ID']>;
};


export type RootMutationTypeUpdateAgentProfileBasicDetailsArgs = {
  mobileNumber: Scalars['String'];
  name: Scalars['String'];
};


export type RootMutationTypeUpdateAgentProfileDetailsArgs = {
  agencyBio: Scalars['String'];
  agencyLogoUserMediaId: Scalars['ID'];
  agencyName: Scalars['String'];
  bio: Scalars['String'];
  profilePictureUserMediaId?: Maybe<Scalars['ID']>;
};


export type RootMutationTypeUpdateDraftPropertyAppraisalAdditionalNotesArgs = {
  additionalNotes: Scalars['String'];
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypeUpdateDraftPropertyAppraisalCommissionArgs = {
  commissionType: AgentCommissionCommissionType;
  flatFeeCostDollars?: Maybe<Scalars['Float']>;
  percentageRate?: Maybe<Scalars['Float']>;
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypeUpdateDraftPropertyAppraisalComparableSalesArgs = {
  comparableSales: Array<PropertyAppraisalComparableSaleInput>;
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypeUpdateDraftPropertyAppraisalMarketingFeeArgs = {
  costDollars: Scalars['Float'];
  marketingInclusions: Array<Scalars['String']>;
  propertyAppraisalId: Scalars['ID'];
};


export type RootMutationTypeUpdateDraftPropertyAppraisalSellingRangeArgs = {
  propertyAppraisalId: Scalars['ID'];
  sellingRangeMax: Scalars['Int'];
  sellingRangeMin: Scalars['Int'];
};


export type RootMutationTypeUpdateDraftPropertyAppraisalVideoArgs = {
  propertyAppraisalId: Scalars['ID'];
  videoUserMediaId?: Maybe<Scalars['ID']>;
};


export type RootMutationTypeUpdatePropertyDetailsArgs = {
  propertyDetails: PropertyDetailsInput;
  propertyId: Scalars['ID'];
};


export type RootMutationTypeUpdatePropertySaleInformationArgs = {
  propertyId: Scalars['ID'];
  propertySaleInformation: PropertySaleInformationInput;
};


export type RootMutationTypeUpdatePropertyTypeArgs = {
  propertyId: Scalars['ID'];
  propertyType: PropertyType;
};


export type RootMutationTypeUpdateUserDetailsArgs = {
  email?: Maybe<Scalars['String']>;
  isPrivacyPolicyAccepted?: Maybe<Scalars['Boolean']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextPage?: Maybe<Scalars['String']>;
  socialEmail?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUpdateUserPasswordArgs = {
  mobileNumber?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};


export type RootMutationTypeUpdateUserPasswordWithResetTokenArgs = {
  password: Scalars['String'];
  passwordResetToken: Scalars['String'];
  userId: Scalars['ID'];
};


export type RootMutationTypeVerifyEmailArgs = {
  emailVerificationToken: Scalars['String'];
};

export type NewUserMedia = {
  __typename?: 'NewUserMedia';
  uploadUrl: Scalars['String'];
  userMedia: UserMedia;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  cardBrand?: Maybe<Scalars['String']>;
  cardSuffix?: Maybe<Scalars['String']>;
};

/** A property that has been sent to agents (if any), it may or may not be completed yet */
export type Property = {
  __typename?: 'Property';
  additionalFeatures?: Maybe<Array<PropertyAdditionalFeature>>;
  additionalInformation?: Maybe<Scalars['String']>;
  completeType?: Maybe<PropertyCompleteType>;
  detailsHidden: Scalars['Boolean'];
  estimatedSaleValue?: Maybe<Scalars['Int']>;
  estimatedSaleValueRange?: Maybe<PropertyEstimatedSaleValueRange>;
  floorplans: Array<PropertyMedia>;
  id: Scalars['ID'];
  idealTimeframeOfSale?: Maybe<PropertyIdealTimeframeOfSale>;
  landSizeSqm?: Maybe<Scalars['Int']>;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfCarSpaces?: Maybe<Scalars['Int']>;
  numberOfLivingSpaces?: Maybe<Scalars['Int']>;
  numberOfToilets?: Maybe<Scalars['Int']>;
  photos: Array<PropertyMedia>;
  preferredMethodOfSale: PropertyPreferredMethodOfSale;
  propertyAge?: Maybe<Scalars['Int']>;
  propertyAppraisals: Array<PropertyAppraisal>;
  propertySizeSqm?: Maybe<Scalars['Int']>;
  propertyType: PropertyType;
  state: PropertyState;
  status: Scalars['String'];
  streetName: Scalars['String'];
  /** @deprecated Only use streetName */
  streetNumber: Scalars['String'];
  submittedAt: Scalars['DateTime'];
  suburb: Suburb;
  videos: Array<PropertyMedia>;
};

export enum PropertyAdditionalFeature {
  Balcony = 'BALCONY',
  BuiltInRobes = 'BUILT_IN_ROBES',
  Cooling = 'COOLING',
  Ensuite = 'ENSUITE',
  Heating = 'HEATING',
  OutdoorArea = 'OUTDOOR_AREA',
  Pool = 'POOL',
  Spa = 'SPA',
  Study = 'STUDY'
}

export type PropertyAppraisal = {
  __typename?: 'PropertyAppraisal';
  additionalNotes?: Maybe<Scalars['String']>;
  agent: AgentProfile;
  appointmentRequestedBySeller: Scalars['Boolean'];
  appraisalVideo?: Maybe<UserMedia>;
  commission: AgentCommission;
  comparableSales: Array<PropertyAppraisalComparableSale>;
  id: Scalars['ID'];
  marketingFee: AgentMarketingFee;
  property: Property;
  seenBySeller: Scalars['Boolean'];
  sellingRangeMax: Scalars['Int'];
  sellingRangeMin: Scalars['Int'];
  sentAt?: Maybe<Scalars['DateTime']>;
  state: PropertyAppraisalState;
};

export type PropertyAppraisalComparableSale = {
  __typename?: 'PropertyAppraisalComparableSale';
  address: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['String'];
  salePriceDollars: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
};

export type PropertyAppraisalComparableSaleInput = {
  address: Scalars['String'];
  salePriceDollars: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
};

export type PropertyAppraisalRequest = {
  __typename?: 'PropertyAppraisalRequest';
  agent: AgentProfile;
  draftPropertyAppraisal?: Maybe<DraftPropertyAppraisal>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  paymentConditionAccepted?: Maybe<Scalars['Boolean']>;
  property: Property;
  propertyAppraisal?: Maybe<PropertyAppraisal>;
  requestedAt: Scalars['DateTime'];
  seenByAgent: Scalars['Boolean'];
  state: PropertyAppraisalRequestState;
};

export type PropertyAppraisalRequestConnection = {
  __typename?: 'PropertyAppraisalRequestConnection';
  edges?: Maybe<Array<Maybe<PropertyAppraisalRequestEdge>>>;
  pageInfo: PageInfo;
};

export type PropertyAppraisalRequestEdge = {
  __typename?: 'PropertyAppraisalRequestEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PropertyAppraisalRequest>;
};

export enum PropertyAppraisalRequestState {
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  NotRequired = 'NOT_REQUIRED',
  Pending = 'PENDING'
}

export enum PropertyAppraisalState {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export enum PropertyCompleteType {
  DecidedNotToSell = 'DECIDED_NOT_TO_SELL',
  DecidedNotToUseAgentspot = 'DECIDED_NOT_TO_USE_AGENTSPOT',
  SoldUnconditionally = 'SOLD_UNCONDITIONALLY'
}

export enum PropertyCompleteTypeWithoutAgentspot {
  DecidedNotToSell = 'DECIDED_NOT_TO_SELL',
  DecidedNotToUseAgentspot = 'DECIDED_NOT_TO_USE_AGENTSPOT'
}

export type PropertyConnection = {
  __typename?: 'PropertyConnection';
  edges?: Maybe<Array<Maybe<PropertyEdge>>>;
  pageInfo: PageInfo;
};

export type PropertyDetailsInput = {
  additionalFeatures?: Maybe<Array<PropertyAdditionalFeature>>;
  landSizeSqm?: Maybe<Scalars['Int']>;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfCarSpaces?: Maybe<Scalars['Int']>;
  numberOfLivingSpaces?: Maybe<Scalars['Int']>;
  numberOfToilets?: Maybe<Scalars['Int']>;
  propertyAge?: Maybe<Scalars['Int']>;
  propertySizeSqm?: Maybe<Scalars['Int']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  suburbId?: Maybe<Scalars['ID']>;
};

export type PropertyEdge = {
  __typename?: 'PropertyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Property>;
};

export enum PropertyEstimatedSaleValueRange {
  Above_2Point_5M = 'ABOVE_2_POINT_5_M',
  Between_1Point_5MTo_2M = 'BETWEEN_1_POINT_5_M_TO_2M',
  Between_1MTo_1Point_5M = 'BETWEEN_1M_TO_1_POINT_5_M',
  Between_250KTo_500K = 'BETWEEN_250K_TO_500K',
  Between_2MTo_2Point_5M = 'BETWEEN_2M_TO_2_POINT_5_M',
  Between_500KTo_750K = 'BETWEEN_500K_TO_750K',
  Between_750KTo_1M = 'BETWEEN_750K_TO_1M',
  Under_250K = 'UNDER_250K'
}

export enum PropertyIdealTimeframeOfSale {
  OneToThreeMonths = 'ONE_TO_THREE_MONTHS',
  SixToTwelveMonths = 'SIX_TO_TWELVE_MONTHS',
  ThreeToSixMonths = 'THREE_TO_SIX_MONTHS',
  TwelveToTwentyFourMonths = 'TWELVE_TO_TWENTY_FOUR_MONTHS',
  ZeroToOneMonth = 'ZERO_TO_ONE_MONTH'
}

export type PropertyMedia = {
  __typename?: 'PropertyMedia';
  id: Scalars['ID'];
  order: Scalars['Int'];
  type: PropertyMediaType;
  userMedia: UserMedia;
};

export enum PropertyMediaType {
  FloorPlan = 'FLOOR_PLAN',
  Photo = 'PHOTO',
  Video = 'VIDEO'
}

export enum PropertyPreferredMethodOfSale {
  Auction = 'AUCTION',
  AuctionOrPrivate = 'AUCTION_OR_PRIVATE',
  Private = 'PRIVATE'
}

export enum PropertyQuestionRequirement {
  NotRequired = 'NOT_REQUIRED',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export type PropertySale = {
  __typename?: 'PropertySale';
  contractDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  property: Property;
  salePrice?: Maybe<Scalars['Int']>;
  sellerName?: Maybe<Scalars['String']>;
  state?: Maybe<PropertySaleState>;
};

export type PropertySaleInformationInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  estimatedSaleValue?: Maybe<Scalars['Int']>;
  estimatedSaleValueRange?: Maybe<PropertyEstimatedSaleValueRange>;
  idealTimeframeOfSale?: Maybe<PropertyIdealTimeframeOfSale>;
  preferredMethodOfSale?: Maybe<PropertyPreferredMethodOfSale>;
};

export enum PropertySaleState {
  ConfirmedByAgent = 'CONFIRMED_BY_AGENT',
  DetailsProvidedByAgent = 'DETAILS_PROVIDED_BY_AGENT',
  ReportedBySeller = 'REPORTED_BY_SELLER'
}

export enum PropertyState {
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
  Draft = 'DRAFT'
}

export enum PropertyType {
  Acreage = 'ACREAGE',
  ApartmentOrUnit = 'APARTMENT_OR_UNIT',
  BlockOfUnits = 'BLOCK_OF_UNITS',
  House = 'HOUSE',
  Land = 'LAND',
  RetirementLiving = 'RETIREMENT_LIVING',
  Townhouse = 'TOWNHOUSE'
}

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Finds agencies matching the given search term */
  agencies: Array<Agency>;
  /** Finds up to 5 agents within 15km of the provided draft property */
  agentsForProperty: Array<AgentProfile>;
  /** Returns an appointment request for an agent, if it was sent to them */
  appointmentRequest: AppointmentRequest;
  /** Returns the current (most recent) draft listing for the current user */
  currentDraftProperty?: Maybe<DraftProperty>;
  /** Returns a draft listing if it belongs to the current user */
  draftProperty: DraftProperty;
  /** Finds the closest matching suburb for a google places search. If no close matches are found this defaults to the capital city of the provided state */
  getSuburbFromSearch: Suburb;
  /** Returns the current user's account */
  me: User;
  /** Returns the number of suburb subscriptions free trial claimed and allowed for this user */
  myAgentSuburbSubscriptionFreeTrialState: AgentSuburbSubscriptionFreeTrialState;
  /** Returns all of the user's appointment requests ordered from the most recently requested */
  myAppointmentRequests?: Maybe<AppointmentRequestConnection>;
  /** Returns all of the user's complete property appraisal request ordered from the most recently requested */
  myCompleteAppraisalRequests?: Maybe<PropertyAppraisalRequestConnection>;
  myDefaultPaymentMethod?: Maybe<PaymentMethod>;
  /** Returns all of the user's draft properties in ordered from the most recently started */
  myDraftProperties?: Maybe<DraftPropertyConnection>;
  /** Returns all of the user's pending and expired property appraisal requests ordered from the most recently requested */
  myPendingAppraisalRequests?: Maybe<PropertyAppraisalRequestConnection>;
  /** Returns all of the user's active and completed properties in ordered from the most recently submitted */
  myProperties?: Maybe<PropertyConnection>;
  /** Returns the suburbs which the agent is currently subscribed to. */
  mySubscribedSuburbs: Array<SubscribedSuburb>;
  /** Returns a active or complete property listing if it belongs to the current user */
  property: Property;
  /** Returns a property appraisal for a seller, if it is for one of their properties */
  propertyAppraisal: PropertyAppraisal;
  /** Returns a property appraisal request for an agent, if it belongs to them */
  propertyAppraisalRequest: PropertyAppraisalRequest;
  /** Returns a property sale if it if the current user is the sale's selected agent and the sale has not yet been confirmed */
  propertySale: PropertySale;
  /** Gets the current cashback amount in dollars available to sellers for selling their property through AgentSpot */
  staticCashbackAmount: Scalars['Int'];
  /** Gets the current sale commision amount in dollars charged to the AgentSpot of they sell a property referred to them through AgentSpot */
  staticCommissionAmount: Scalars['Int'];
  /** Returns the suburbs with the provided ids */
  suburbs: Array<Suburb>;
  /** Returns the count of unread appointment requests */
  unreadAppointmentRequestsCount: Scalars['Int'];
  /** Gets a user media, if it belongs to the current user */
  userMedia: UserMedia;
};


export type RootQueryTypeAgenciesArgs = {
  searchTerm: Scalars['String'];
};


export type RootQueryTypeAgentsForPropertyArgs = {
  propertyId: Scalars['ID'];
};


export type RootQueryTypeAppointmentRequestArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeDraftPropertyArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeGetSuburbFromSearchArgs = {
  postcode?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  suburbs: Array<Scalars['String']>;
};


export type RootQueryTypeMyAppointmentRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeMyCompleteAppraisalRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeMyDraftPropertiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeMyPendingAppraisalRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeMyPropertiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RootQueryTypePropertyArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePropertyAppraisalArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePropertyAppraisalRequestArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePropertySaleArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeSuburbsArgs = {
  ids: Array<Scalars['ID']>;
};


export type RootQueryTypeUserMediaArgs = {
  id: Scalars['ID'];
};

export type SellerProfile = {
  __typename?: 'SellerProfile';
  id: Scalars['ID'];
  isPrivacyPolicyAccepted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  nextPage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  token: Scalars['String'];
  user: User;
};

export enum State {
  Act = 'ACT',
  Nsw = 'NSW',
  Nt = 'NT',
  Qld = 'QLD',
  Sa = 'SA',
  Tas = 'TAS',
  Vic = 'VIC',
  Wa = 'WA'
}

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String'];
};

export type SubscribedSuburb = {
  __typename?: 'SubscribedSuburb';
  id: Scalars['ID'];
  isFreeTrial: Scalars['Boolean'];
  subscriptionExpiresAt: Scalars['DateTime'];
  subscriptionToRenew: Scalars['Boolean'];
  suburb: Suburb;
};

export type Suburb = {
  __typename?: 'Suburb';
  id: Scalars['ID'];
  name: Scalars['String'];
  nearbySuburbs: Array<Suburb>;
  postcode: Scalars['String'];
  state: State;
  subscribedAgentsCount?: Maybe<Scalars['Int']>;
  subscribedAgentsMax?: Maybe<Scalars['Int']>;
};


export type SuburbNearbySuburbsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type User = {
  __typename?: 'User';
  agentProfile?: Maybe<AgentProfile>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEmailVerified: Scalars['Boolean'];
  isGhostUser: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  profileComplete: Scalars['Boolean'];
  sellerProfile?: Maybe<SellerProfile>;
  userType: UserType;
};

export type UserMedia = {
  __typename?: 'UserMedia';
  id: Scalars['ID'];
  mainFileReady: Scalars['Boolean'];
  mimeType: Scalars['String'];
  thumbnailReady: Scalars['Boolean'];
  thumbnailUrl: Scalars['String'];
  type: UserMediaType;
  url: Scalars['String'];
};

export enum UserMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export enum UserType {
  Agent = 'AGENT',
  Seller = 'SELLER'
}

export type PaymentMethodBaseFragment = (
  { __typename?: 'PaymentMethod' }
  & Pick<PaymentMethod, 'cardBrand' | 'cardSuffix'>
);

export type PropertySaleBaseFragment = (
  { __typename?: 'PropertySale' }
  & Pick<PropertySale, 'id' | 'sellerName'>
  & { property: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type SubscribedSuburbBaseFragment = (
  { __typename?: 'SubscribedSuburb' }
  & Pick<SubscribedSuburb, 'id' | 'subscriptionToRenew' | 'subscriptionExpiresAt' | 'isFreeTrial'>
  & { suburb: (
    { __typename?: 'Suburb' }
    & SuburbBaseFragment
  ) }
);

export type SuburbWithAgentStatsFragment = (
  { __typename?: 'Suburb' }
  & Pick<Suburb, 'subscribedAgentsCount' | 'subscribedAgentsMax'>
  & SuburbBaseFragment
);

export type CancelSuburbSubscriptionMutationVariables = Exact<{
  suburbSubscriptionId: Scalars['ID'];
}>;


export type CancelSuburbSubscriptionMutation = (
  { __typename?: 'RootMutationType' }
  & { cancelSuburbSubscription: (
    { __typename?: 'SubscribedSuburb' }
    & SubscribedSuburbBaseFragment
  ) }
);

export type ConfirmPropertySaleMutationVariables = Exact<{
  id: Scalars['ID'];
  salePrice: Scalars['Int'];
  contractDate: Scalars['Date'];
}>;


export type ConfirmPropertySaleMutation = (
  { __typename?: 'RootMutationType' }
  & { confirmPropertySale: (
    { __typename?: 'PropertySale' }
    & PropertySaleBaseFragment
  ) }
);

export type CreateAgentUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateAgentUserMutation = (
  { __typename?: 'RootMutationType' }
  & { createAgentUser: (
    { __typename?: 'Session' }
    & Pick<Session, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserBaseFragment
    ) }
  ) }
);

export type GetSuburbFromSearchWithNearbySuburbsQueryVariables = Exact<{
  suburbs: Array<Scalars['String']> | Scalars['String'];
  state: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
}>;


export type GetSuburbFromSearchWithNearbySuburbsQuery = (
  { __typename?: 'RootQueryType' }
  & { getSuburbFromSearch: (
    { __typename?: 'Suburb' }
    & { nearbySuburbs: Array<(
      { __typename?: 'Suburb' }
      & SuburbWithAgentStatsFragment
    )> }
    & SuburbWithAgentStatsFragment
  ) }
);

export type MyAgentSuburbSubscriptionFreeTrialStateQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAgentSuburbSubscriptionFreeTrialStateQuery = (
  { __typename?: 'RootQueryType' }
  & { myAgentSuburbSubscriptionFreeTrialState: (
    { __typename?: 'AgentSuburbSubscriptionFreeTrialState' }
    & Pick<AgentSuburbSubscriptionFreeTrialState, 'numberOfFreeTrialsClaimed' | 'totalFreeTrialsAllowed'>
  ) }
);

export type MyDefaultPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type MyDefaultPaymentMethodQuery = (
  { __typename?: 'RootQueryType' }
  & { myDefaultPaymentMethod?: Maybe<(
    { __typename?: 'PaymentMethod' }
    & PaymentMethodBaseFragment
  )> }
);

export type MySubscribedSuburbsQueryVariables = Exact<{ [key: string]: never; }>;


export type MySubscribedSuburbsQuery = (
  { __typename?: 'RootQueryType' }
  & { mySubscribedSuburbs: Array<(
    { __typename?: 'SubscribedSuburb' }
    & SubscribedSuburbBaseFragment
  )> }
);

export type PropertySaleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PropertySaleQuery = (
  { __typename?: 'RootQueryType' }
  & { propertySale: (
    { __typename?: 'PropertySale' }
    & PropertySaleBaseFragment
  ) }
);

export type RenewSuburbSubscriptionMutationVariables = Exact<{
  suburbSubscriptionId: Scalars['ID'];
}>;


export type RenewSuburbSubscriptionMutation = (
  { __typename?: 'RootMutationType' }
  & { renewSuburbSubscription: (
    { __typename?: 'SubscribedSuburb' }
    & SubscribedSuburbBaseFragment
  ) }
);

export type StripeConfirmPaymentMethodMutationVariables = Exact<{
  stripePaymentMethodId: Scalars['String'];
}>;


export type StripeConfirmPaymentMethodMutation = (
  { __typename?: 'RootMutationType' }
  & { stripeConfirmPaymentMethod: (
    { __typename?: 'PaymentMethod' }
    & PaymentMethodBaseFragment
  ) }
);

export type StripeCreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type StripeCreateSetupIntentMutation = (
  { __typename?: 'RootMutationType' }
  & { stripeCreateSetupIntent: (
    { __typename?: 'StripeSetupIntent' }
    & Pick<StripeSetupIntent, 'clientSecret'>
  ) }
);

export type SubscribeToSuburbsMutationVariables = Exact<{
  suburbIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SubscribeToSuburbsMutation = (
  { __typename?: 'RootMutationType' }
  & { subscribeToSuburbs: Array<(
    { __typename?: 'SubscribedSuburb' }
    & SubscribedSuburbBaseFragment
  )> }
);

export type SuburbsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SuburbsQuery = (
  { __typename?: 'RootQueryType' }
  & { suburbs: Array<(
    { __typename?: 'Suburb' }
    & SuburbBaseFragment
  )> }
);

export type UpdateAgentProfileBasicDetailsMutationVariables = Exact<{
  name: Scalars['String'];
  mobileNumber: Scalars['String'];
}>;


export type UpdateAgentProfileBasicDetailsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateAgentProfileBasicDetails: (
    { __typename?: 'User' }
    & UserBaseFragment
  ) }
);

export type UpdateAgentProfileDetailsMutationVariables = Exact<{
  profilePictureUserMediaId?: Maybe<Scalars['ID']>;
  bio: Scalars['String'];
  agencyLogoUserMediaId: Scalars['ID'];
  agencyName: Scalars['String'];
  agencyBio: Scalars['String'];
}>;


export type UpdateAgentProfileDetailsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateAgentProfileDetails: (
    { __typename?: 'AgentProfile' }
    & AgentBaseFragment
  ) }
);

export type SendContinueEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendContinueEmailMutation = (
  { __typename?: 'RootMutationType' }
  & { sendContinueEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'message'>
  )> }
);

export type GetUserByTokenMutationVariables = Exact<{
  continueToken: Scalars['String'];
}>;


export type GetUserByTokenMutation = (
  { __typename?: 'RootMutationType' }
  & { getUserByToken: (
    { __typename?: 'Session' }
    & Pick<Session, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserBaseFragment
    ) }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'RootMutationType' }
  & { login: (
    { __typename?: 'Session' }
    & Pick<Session, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserBaseFragment
    ) }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'RootQueryType' }
  & { me: (
    { __typename?: 'User' }
    & UserBaseFragment
  ) }
);

export type ResendVerificationEmailMutationVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type ResendVerificationEmailMutation = (
  { __typename?: 'RootMutationType' }
  & { resendVerificationEmail: (
    { __typename?: 'User' }
    & Pick<User, 'message'>
  ) }
);

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetEmailMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<RootMutationType, 'sendPasswordResetEmail'>
);

export type UpdateUserDetailsMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  socialEmail?: Maybe<Scalars['String']>;
  isPrivacyPolicyAccepted?: Maybe<Scalars['Boolean']>;
  nextPage?: Maybe<Scalars['String']>;
}>;


export type UpdateUserDetailsMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUserDetails: (
    { __typename?: 'User' }
    & UserBaseFragment
  ) }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUserPassword: (
    { __typename?: 'User' }
    & UserBaseFragment
  ) }
);

export type UpdateUserPasswordWithResetTokenMutationVariables = Exact<{
  userId: Scalars['ID'];
  passwordResetToken: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateUserPasswordWithResetTokenMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUserPasswordWithResetToken: (
    { __typename?: 'User' }
    & UserBaseFragment
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  emailVerificationToken: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'RootMutationType' }
  & { verifyEmail: (
    { __typename?: 'User' }
    & Pick<User, 'message'>
  ) }
);

export type DraftPropertyBaseFragment = (
  { __typename?: 'DraftProperty' }
  & Pick<DraftProperty, 'id' | 'state' | 'propertyType' | 'streetNumber' | 'streetName' | 'numberOfBedroomsQuestionRequirement' | 'numberOfBedrooms' | 'numberOfBathroomsQuestionRequirement' | 'numberOfBathrooms' | 'numberOfToiletsQuestionRequirement' | 'numberOfToilets' | 'numberOfCarSpacesQuestionRequirement' | 'numberOfCarSpaces' | 'numberOfLivingSpacesQuestionRequirement' | 'numberOfLivingSpaces' | 'propertySizeSqmQuestionRequirement' | 'propertySizeSqm' | 'landSizeSqmQuestionRequirement' | 'landSizeSqm' | 'propertyAgeQuestionRequirement' | 'propertyAge' | 'additionalFeaturesQuestionRequirement' | 'additionalFeatures' | 'estimatedSaleValue' | 'estimatedSaleValueRange' | 'idealTimeframeOfSale' | 'preferredMethodOfSale' | 'additionalInformation'>
  & { suburb: (
    { __typename?: 'Suburb' }
    & Pick<Suburb, 'id' | 'name' | 'state' | 'postcode'>
  ), photos: Array<(
    { __typename?: 'PropertyMedia' }
    & PropertyMediaBaseFragment
  )>, floorplans: Array<(
    { __typename?: 'PropertyMedia' }
    & PropertyMediaBaseFragment
  )>, videos: Array<(
    { __typename?: 'PropertyMedia' }
    & PropertyMediaBaseFragment
  )> }
);

export type AgentsForPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type AgentsForPropertyQuery = (
  { __typename?: 'RootQueryType' }
  & { agentsForProperty: Array<(
    { __typename?: 'AgentProfile' }
    & AgentBaseFragment
  )> }
);

export type AttachUserMediaToDraftPropertyMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  propertyMediaType: PropertyMediaType;
  userMediaId: Scalars['ID'];
}>;


export type AttachUserMediaToDraftPropertyMutation = (
  { __typename?: 'RootMutationType' }
  & { attachUserMediaToDraftProperty: (
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  ) }
);

export type CreateGhostUserMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateGhostUserMutation = (
  { __typename?: 'RootMutationType' }
  & { createGhostUser: (
    { __typename?: 'Session' }
    & Pick<Session, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserBaseFragment
    ) }
  ) }
);

export type CreateUserMediaMutationVariables = Exact<{
  fileExtension: Scalars['String'];
  mimeType: Scalars['String'];
}>;


export type CreateUserMediaMutation = (
  { __typename?: 'RootMutationType' }
  & { createUserMedia: (
    { __typename?: 'NewUserMedia' }
    & Pick<NewUserMedia, 'uploadUrl'>
    & { userMedia: (
      { __typename?: 'UserMedia' }
      & UserMediaBaseFragment
    ) }
  ) }
);

export type CurrentDraftPropertyQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentDraftPropertyQuery = (
  { __typename?: 'RootQueryType' }
  & { currentDraftProperty?: Maybe<(
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  )> }
);

export type GetSuburbFromSearchQueryVariables = Exact<{
  suburbs: Array<Scalars['String']> | Scalars['String'];
  state: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
}>;


export type GetSuburbFromSearchQuery = (
  { __typename?: 'RootQueryType' }
  & { getSuburbFromSearch: (
    { __typename?: 'Suburb' }
    & SuburbBaseFragment
  ) }
);

export type PublishPropertyMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  selectedAgents: Array<Scalars['ID']> | Scalars['ID'];
  askAgentSpotForMoreAgents: Scalars['Boolean'];
}>;


export type PublishPropertyMutation = (
  { __typename?: 'RootMutationType' }
  & { publishProperty: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type RemovePropertyMediaFromDraftPropertyMutationVariables = Exact<{
  propertyMediaId: Scalars['ID'];
}>;


export type RemovePropertyMediaFromDraftPropertyMutation = (
  { __typename?: 'RootMutationType' }
  & { removePropertyMediaFromDraftProperty: (
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  ) }
);

export type StartNewDraftPropertyMutationVariables = Exact<{
  streetName: Scalars['String'];
  suburbId: Scalars['ID'];
}>;


export type StartNewDraftPropertyMutation = (
  { __typename?: 'RootMutationType' }
  & { startNewDraftProperty: (
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  ) }
);

export type UpdatePropertyDetailsMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  propertyDetails: PropertyDetailsInput;
}>;


export type UpdatePropertyDetailsMutation = (
  { __typename?: 'RootMutationType' }
  & { updatePropertyDetails: (
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  ) }
);

export type UpdatePropertySaleInformationMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  propertySaleInformation: PropertySaleInformationInput;
}>;


export type UpdatePropertySaleInformationMutation = (
  { __typename?: 'RootMutationType' }
  & { updatePropertySaleInformation: (
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  ) }
);

export type UpdatePropertyTypeMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  propertyType: PropertyType;
}>;


export type UpdatePropertyTypeMutation = (
  { __typename?: 'RootMutationType' }
  & { updatePropertyType: (
    { __typename?: 'DraftProperty' }
    & DraftPropertyBaseFragment
  ) }
);

export type UserMediaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserMediaQuery = (
  { __typename?: 'RootQueryType' }
  & { userMedia: (
    { __typename?: 'UserMedia' }
    & UserMediaBaseFragment
  ) }
);

export type AgentCommissionBaseFragment = (
  { __typename?: 'AgentCommission' }
  & Pick<AgentCommission, 'id' | 'commissionType' | 'percentageRate' | 'flatFeeCostDollars'>
);

export type AgentMarketingFeeBaseFragment = (
  { __typename?: 'AgentMarketingFee' }
  & Pick<AgentMarketingFee, 'id' | 'costDollars' | 'marketingInclusions'>
);

export type AppointmentRequestBaseFragment = (
  { __typename?: 'AppointmentRequest' }
  & Pick<AppointmentRequest, 'id' | 'requestedAppointmentDate' | 'requestedAppointmentTime' | 'requestedBySellerAt' | 'seenByAgent' | 'sellerName' | 'sellerEmail' | 'sellerMobileNumber'>
  & { property: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type AppointmentRequestEdgeBaseFragment = (
  { __typename?: 'AppointmentRequestEdge' }
  & Pick<AppointmentRequestEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'AppointmentRequest' }
    & AppointmentRequestBaseFragment
  )> }
);

export type DraftPropertyAppraisalBaseFragment = (
  { __typename?: 'DraftPropertyAppraisal' }
  & Pick<DraftPropertyAppraisal, 'id' | 'state' | 'sellingRangeMin' | 'sellingRangeMax' | 'additionalNotes'>
  & { comparableSales: Array<(
    { __typename?: 'PropertyAppraisalComparableSale' }
    & PropertyAppraisalComparableSaleBaseFragment
  )>, marketingFee?: Maybe<(
    { __typename?: 'AgentMarketingFee' }
    & AgentMarketingFeeBaseFragment
  )>, commission?: Maybe<(
    { __typename?: 'AgentCommission' }
    & AgentCommissionBaseFragment
  )>, appraisalVideo?: Maybe<(
    { __typename?: 'UserMedia' }
    & UserMediaBaseFragment
  )> }
);

export type PropertyAppraisalBaseFragment = (
  { __typename?: 'PropertyAppraisal' }
  & Pick<PropertyAppraisal, 'id' | 'state' | 'sellingRangeMin' | 'sellingRangeMax' | 'additionalNotes' | 'sentAt' | 'appointmentRequestedBySeller'>
  & { comparableSales: Array<(
    { __typename?: 'PropertyAppraisalComparableSale' }
    & PropertyAppraisalComparableSaleBaseFragment
  )>, marketingFee: (
    { __typename?: 'AgentMarketingFee' }
    & AgentMarketingFeeBaseFragment
  ), commission: (
    { __typename?: 'AgentCommission' }
    & AgentCommissionBaseFragment
  ), appraisalVideo?: Maybe<(
    { __typename?: 'UserMedia' }
    & UserMediaBaseFragment
  )>, agent: (
    { __typename?: 'AgentProfile' }
    & AgentBaseFragment
  ), property: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type PropertyAppraisalComparableSaleBaseFragment = (
  { __typename?: 'PropertyAppraisalComparableSale' }
  & Pick<PropertyAppraisalComparableSale, 'id' | 'order' | 'address' | 'salePriceDollars' | 'url'>
);

export type PropertyAppraisalRequestEdgeBaseFragment = (
  { __typename?: 'PropertyAppraisalRequestEdge' }
  & Pick<PropertyAppraisalRequestEdge, 'cursor'>
  & { node?: Maybe<(
    { __typename?: 'PropertyAppraisalRequest' }
    & PropertyAppraisalRequestBaseFragment
  )> }
);

export type PropertyWithAppraisalsFragment = (
  { __typename?: 'Property' }
  & { propertyAppraisals: Array<(
    { __typename?: 'PropertyAppraisal' }
    & PropertyAppraisalBaseFragment
  )> }
  & PropertyBaseFragment
);

export type AcceptPaymentConditionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AcceptPaymentConditionMutation = (
  { __typename?: 'RootMutationType' }
  & { acceptPaymentCondition: (
    { __typename?: 'PropertyAppraisalRequest' }
    & PropertyAppraisalRequestBaseFragment
  ) }
);

export type AppointmentRequestSeenByAgentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AppointmentRequestSeenByAgentMutation = (
  { __typename?: 'RootMutationType' }
  & { appointmentRequestSeenByAgent: (
    { __typename?: 'AppointmentRequest' }
    & AppointmentRequestBaseFragment
  ) }
);

export type MyAppointmentRequestsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type MyAppointmentRequestsQuery = (
  { __typename?: 'RootQueryType' }
  & { myAppointmentRequests?: Maybe<(
    { __typename?: 'AppointmentRequestConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'AppointmentRequestEdge' }
      & AppointmentRequestEdgeBaseFragment
    )>>> }
  )> }
);

export type UnreadAppointmentRequestsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadAppointmentRequestsCountQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'unreadAppointmentRequestsCount'>
);

export type MyCompleteAppraisalRequestsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type MyCompleteAppraisalRequestsQuery = (
  { __typename?: 'RootQueryType' }
  & { myCompleteAppraisalRequests?: Maybe<(
    { __typename?: 'PropertyAppraisalRequestConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'PropertyAppraisalRequestEdge' }
      & PropertyAppraisalRequestEdgeBaseFragment
    )>>> }
  )> }
);

export type MyPendingAppraisalRequestsQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type MyPendingAppraisalRequestsQuery = (
  { __typename?: 'RootQueryType' }
  & { myPendingAppraisalRequests?: Maybe<(
    { __typename?: 'PropertyAppraisalRequestConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'PropertyAppraisalRequestEdge' }
      & PropertyAppraisalRequestEdgeBaseFragment
    )>>> }
  )> }
);

export type MyPropertiesQueryVariables = Exact<{
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type MyPropertiesQuery = (
  { __typename?: 'RootQueryType' }
  & { myProperties?: Maybe<(
    { __typename?: 'PropertyConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'PropertyEdge' }
      & Pick<PropertyEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Property' }
        & PropertyWithAppraisalsFragment
      )> }
    )>>> }
  )> }
);

export type NewPropertyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NewPropertyQuery = (
  { __typename?: 'RootQueryType' }
  & { property: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type PropertyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PropertyQuery = (
  { __typename?: 'RootQueryType' }
  & { property: (
    { __typename?: 'Property' }
    & PropertyWithAppraisalsFragment
  ) }
);

export type PropertyAppraisalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PropertyAppraisalQuery = (
  { __typename?: 'RootQueryType' }
  & { propertyAppraisal: (
    { __typename?: 'PropertyAppraisal' }
    & PropertyAppraisalBaseFragment
  ) }
);

export type PropertyAppraisalRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PropertyAppraisalRequestQuery = (
  { __typename?: 'RootQueryType' }
  & { propertyAppraisalRequest: (
    { __typename?: 'PropertyAppraisalRequest' }
    & PropertyAppraisalRequestBaseFragment
  ) }
);

export type PropertyAppraisalRequestSeenByAgentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PropertyAppraisalRequestSeenByAgentMutation = (
  { __typename?: 'RootMutationType' }
  & { propertyAppraisalRequestSeenByAgent: (
    { __typename?: 'PropertyAppraisalRequest' }
    & PropertyAppraisalRequestBaseFragment
  ) }
);

export type PropertyCompletedWithoutAgentSpotMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  completeType: PropertyCompleteTypeWithoutAgentspot;
}>;


export type PropertyCompletedWithoutAgentSpotMutation = (
  { __typename?: 'RootMutationType' }
  & { propertyCompletedWithoutAgentSpot: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type PropertySoldThroughAgentSpotMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  sellingAgentId: Scalars['ID'];
  accountNumber: Scalars['String'];
  bsb: Scalars['String'];
}>;


export type PropertySoldThroughAgentSpotMutation = (
  { __typename?: 'RootMutationType' }
  & { propertySoldThroughAgentSpot: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ) }
);

export type RequestAppointmentForAgentFromPropertyAppraisalMutationVariables = Exact<{
  appointmentDate: Scalars['Date'];
  appointmentTime: Scalars['Time'];
  propertyAppraisalId: Scalars['ID'];
}>;


export type RequestAppointmentForAgentFromPropertyAppraisalMutation = (
  { __typename?: 'RootMutationType' }
  & { requestAppointmentForAgentFromPropertyAppraisal: (
    { __typename?: 'PropertyAppraisal' }
    & PropertyAppraisalBaseFragment
  ) }
);

export type StartNewDraftPropertyAppraisalMutationVariables = Exact<{
  propertyAppraisalRequestId: Scalars['ID'];
}>;


export type StartNewDraftPropertyAppraisalMutation = (
  { __typename?: 'RootMutationType' }
  & { startNewDraftPropertyAppraisal: (
    { __typename?: 'PropertyAppraisalRequest' }
    & PropertyAppraisalRequestBaseFragment
  ) }
);

export type SubmitPropertyAppraisalMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
}>;


export type SubmitPropertyAppraisalMutation = (
  { __typename?: 'RootMutationType' }
  & { submitPropertyAppraisal: (
    { __typename?: 'PropertyAppraisalRequest' }
    & PropertyAppraisalRequestBaseFragment
  ) }
);

export type UpdateDraftPropertyAppraisalAdditionalNotesMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
  additionalNotes: Scalars['String'];
}>;


export type UpdateDraftPropertyAppraisalAdditionalNotesMutation = (
  { __typename?: 'RootMutationType' }
  & { updateDraftPropertyAppraisalAdditionalNotes: (
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  ) }
);

export type UpdateDraftPropertyAppraisalCommissionMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
  commissionType: AgentCommissionCommissionType;
  percentageRate?: Maybe<Scalars['Float']>;
  flatFeeCostDollars?: Maybe<Scalars['Float']>;
}>;


export type UpdateDraftPropertyAppraisalCommissionMutation = (
  { __typename?: 'RootMutationType' }
  & { updateDraftPropertyAppraisalCommission: (
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  ) }
);

export type UpdateDraftPropertyAppraisalComparableSalesMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
  comparableSales: Array<PropertyAppraisalComparableSaleInput> | PropertyAppraisalComparableSaleInput;
}>;


export type UpdateDraftPropertyAppraisalComparableSalesMutation = (
  { __typename?: 'RootMutationType' }
  & { updateDraftPropertyAppraisalComparableSales: (
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  ) }
);

export type UpdateDraftPropertyAppraisalMarketingFeeMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
  costDollars: Scalars['Float'];
  marketingInclusions: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateDraftPropertyAppraisalMarketingFeeMutation = (
  { __typename?: 'RootMutationType' }
  & { updateDraftPropertyAppraisalMarketingFee: (
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  ) }
);

export type UpdateDraftPropertyAppraisalSellingRangeMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
  sellingRangeMin: Scalars['Int'];
  sellingRangeMax: Scalars['Int'];
}>;


export type UpdateDraftPropertyAppraisalSellingRangeMutation = (
  { __typename?: 'RootMutationType' }
  & { updateDraftPropertyAppraisalSellingRange: (
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  ) }
);

export type UpdateDraftPropertyAppraisalVideoMutationVariables = Exact<{
  propertyAppraisalId: Scalars['ID'];
  videoUserMediaId?: Maybe<Scalars['ID']>;
}>;


export type UpdateDraftPropertyAppraisalVideoMutation = (
  { __typename?: 'RootMutationType' }
  & { updateDraftPropertyAppraisalVideo: (
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  ) }
);

export type StaticCashbackAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type StaticCashbackAmountQuery = (
  { __typename?: 'RootQueryType' }
  & Pick<RootQueryType, 'staticCashbackAmount'>
);

export type AgentBaseFragment = (
  { __typename?: 'AgentProfile' }
  & Pick<AgentProfile, 'id' | 'name' | 'bio' | 'badge' | 'numberOfEngagements' | 'agencyName' | 'agencyBio'>
  & { profilePicture?: Maybe<(
    { __typename?: 'UserMedia' }
    & UserMediaBaseFragment
  )>, agencyLogo?: Maybe<(
    { __typename?: 'UserMedia' }
    & UserMediaBaseFragment
  )> }
);

export type PropertyAppraisalRequestBaseFragment = (
  { __typename?: 'PropertyAppraisalRequest' }
  & Pick<PropertyAppraisalRequest, 'id' | 'state' | 'requestedAt' | 'expiresAt' | 'seenByAgent' | 'paymentConditionAccepted'>
  & { property: (
    { __typename?: 'Property' }
    & PropertyBaseFragment
  ), draftPropertyAppraisal?: Maybe<(
    { __typename?: 'DraftPropertyAppraisal' }
    & DraftPropertyAppraisalBaseFragment
  )>, propertyAppraisal?: Maybe<(
    { __typename?: 'PropertyAppraisal' }
    & PropertyAppraisalBaseFragment
  )> }
);

export type PropertyBaseFragment = (
  { __typename?: 'Property' }
  & Pick<Property, 'id' | 'state' | 'propertyType' | 'streetNumber' | 'streetName' | 'numberOfBedrooms' | 'numberOfBathrooms' | 'numberOfToilets' | 'numberOfCarSpaces' | 'numberOfLivingSpaces' | 'propertySizeSqm' | 'landSizeSqm' | 'propertyAge' | 'additionalFeatures' | 'estimatedSaleValue' | 'estimatedSaleValueRange' | 'idealTimeframeOfSale' | 'preferredMethodOfSale' | 'additionalInformation' | 'submittedAt' | 'completeType' | 'detailsHidden'>
  & { suburb: (
    { __typename?: 'Suburb' }
    & Pick<Suburb, 'id' | 'name' | 'state' | 'postcode'>
  ), photos: Array<(
    { __typename?: 'PropertyMedia' }
    & PropertyMediaBaseFragment
  )>, floorplans: Array<(
    { __typename?: 'PropertyMedia' }
    & PropertyMediaBaseFragment
  )>, videos: Array<(
    { __typename?: 'PropertyMedia' }
    & PropertyMediaBaseFragment
  )> }
);

export type PropertyMediaBaseFragment = (
  { __typename?: 'PropertyMedia' }
  & Pick<PropertyMedia, 'id' | 'type' | 'order'>
  & { userMedia: (
    { __typename?: 'UserMedia' }
    & UserMediaBaseFragment
  ) }
);

export type SuburbBaseFragment = (
  { __typename?: 'Suburb' }
  & Pick<Suburb, 'id' | 'name' | 'state' | 'postcode'>
);

export type UserBaseFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'userType' | 'isGhostUser' | 'isEmailVerified' | 'mobileNumber' | 'profileComplete'>
  & { agentProfile?: Maybe<(
    { __typename?: 'AgentProfile' }
    & AgentBaseFragment
  )>, sellerProfile?: Maybe<(
    { __typename?: 'SellerProfile' }
    & Pick<SellerProfile, 'name' | 'isPrivacyPolicyAccepted' | 'nextPage' | 'status'>
  )> }
);

export type UserMediaBaseFragment = (
  { __typename?: 'UserMedia' }
  & Pick<UserMedia, 'id' | 'type' | 'mimeType' | 'url' | 'thumbnailUrl' | 'mainFileReady' | 'thumbnailReady'>
);

export const PaymentMethodBaseFragmentDoc = gql`
    fragment PaymentMethodBase on PaymentMethod {
  cardBrand
  cardSuffix
}
    `;
export const UserMediaBaseFragmentDoc = gql`
    fragment UserMediaBase on UserMedia {
  id
  type
  mimeType
  url
  thumbnailUrl
  mainFileReady
  thumbnailReady
}
    `;
export const PropertyMediaBaseFragmentDoc = gql`
    fragment PropertyMediaBase on PropertyMedia {
  id
  userMedia {
    ...UserMediaBase
  }
  type
  order
}
    ${UserMediaBaseFragmentDoc}`;
export const PropertyBaseFragmentDoc = gql`
    fragment PropertyBase on Property {
  id
  state
  propertyType
  streetNumber
  streetName
  suburb {
    id
    name
    state
    postcode
  }
  numberOfBedrooms
  numberOfBathrooms
  numberOfToilets
  numberOfCarSpaces
  numberOfLivingSpaces
  propertySizeSqm
  landSizeSqm
  propertyAge
  additionalFeatures
  estimatedSaleValue
  estimatedSaleValueRange
  idealTimeframeOfSale
  preferredMethodOfSale
  additionalInformation
  photos {
    ...PropertyMediaBase
  }
  floorplans {
    ...PropertyMediaBase
  }
  videos {
    ...PropertyMediaBase
  }
  submittedAt
  completeType
  detailsHidden
}
    ${PropertyMediaBaseFragmentDoc}`;
export const PropertySaleBaseFragmentDoc = gql`
    fragment PropertySaleBase on PropertySale {
  id
  property {
    ...PropertyBase
  }
  sellerName
}
    ${PropertyBaseFragmentDoc}`;
export const SuburbBaseFragmentDoc = gql`
    fragment SuburbBase on Suburb {
  id
  name
  state
  postcode
}
    `;
export const SubscribedSuburbBaseFragmentDoc = gql`
    fragment SubscribedSuburbBase on SubscribedSuburb {
  id
  subscriptionToRenew
  subscriptionExpiresAt
  suburb {
    ...SuburbBase
  }
  isFreeTrial
}
    ${SuburbBaseFragmentDoc}`;
export const SuburbWithAgentStatsFragmentDoc = gql`
    fragment SuburbWithAgentStats on Suburb {
  ...SuburbBase
  subscribedAgentsCount
  subscribedAgentsMax
}
    ${SuburbBaseFragmentDoc}`;
export const DraftPropertyBaseFragmentDoc = gql`
    fragment DraftPropertyBase on DraftProperty {
  id
  state
  propertyType
  streetNumber
  streetName
  suburb {
    id
    name
    state
    postcode
  }
  numberOfBedroomsQuestionRequirement
  numberOfBedrooms
  numberOfBathroomsQuestionRequirement
  numberOfBathrooms
  numberOfToiletsQuestionRequirement
  numberOfToilets
  numberOfCarSpacesQuestionRequirement
  numberOfCarSpaces
  numberOfLivingSpacesQuestionRequirement
  numberOfLivingSpaces
  propertySizeSqmQuestionRequirement
  propertySizeSqm
  landSizeSqmQuestionRequirement
  landSizeSqm
  propertyAgeQuestionRequirement
  propertyAge
  additionalFeaturesQuestionRequirement
  additionalFeatures
  estimatedSaleValue
  estimatedSaleValueRange
  idealTimeframeOfSale
  preferredMethodOfSale
  additionalInformation
  photos {
    ...PropertyMediaBase
  }
  floorplans {
    ...PropertyMediaBase
  }
  videos {
    ...PropertyMediaBase
  }
}
    ${PropertyMediaBaseFragmentDoc}`;
export const AppointmentRequestBaseFragmentDoc = gql`
    fragment AppointmentRequestBase on AppointmentRequest {
  id
  property {
    ...PropertyBase
  }
  requestedAppointmentDate
  requestedAppointmentTime
  requestedBySellerAt
  seenByAgent
  sellerName
  sellerEmail
  sellerMobileNumber
}
    ${PropertyBaseFragmentDoc}`;
export const AppointmentRequestEdgeBaseFragmentDoc = gql`
    fragment AppointmentRequestEdgeBase on AppointmentRequestEdge {
  cursor
  node {
    ...AppointmentRequestBase
  }
}
    ${AppointmentRequestBaseFragmentDoc}`;
export const PropertyAppraisalComparableSaleBaseFragmentDoc = gql`
    fragment PropertyAppraisalComparableSaleBase on PropertyAppraisalComparableSale {
  id
  order
  address
  salePriceDollars
  url
}
    `;
export const AgentMarketingFeeBaseFragmentDoc = gql`
    fragment AgentMarketingFeeBase on AgentMarketingFee {
  id
  costDollars
  marketingInclusions
}
    `;
export const AgentCommissionBaseFragmentDoc = gql`
    fragment AgentCommissionBase on AgentCommission {
  id
  commissionType
  percentageRate
  flatFeeCostDollars
}
    `;
export const DraftPropertyAppraisalBaseFragmentDoc = gql`
    fragment DraftPropertyAppraisalBase on DraftPropertyAppraisal {
  id
  state
  sellingRangeMin
  sellingRangeMax
  comparableSales {
    ...PropertyAppraisalComparableSaleBase
  }
  marketingFee {
    ...AgentMarketingFeeBase
  }
  commission {
    ...AgentCommissionBase
  }
  appraisalVideo {
    ...UserMediaBase
  }
  additionalNotes
}
    ${PropertyAppraisalComparableSaleBaseFragmentDoc}
${AgentMarketingFeeBaseFragmentDoc}
${AgentCommissionBaseFragmentDoc}
${UserMediaBaseFragmentDoc}`;
export const AgentBaseFragmentDoc = gql`
    fragment AgentBase on AgentProfile {
  id
  name
  bio
  badge
  numberOfEngagements
  profilePicture {
    ...UserMediaBase
  }
  agencyLogo {
    ...UserMediaBase
  }
  agencyName
  agencyBio
}
    ${UserMediaBaseFragmentDoc}`;
export const PropertyAppraisalBaseFragmentDoc = gql`
    fragment PropertyAppraisalBase on PropertyAppraisal {
  id
  state
  sellingRangeMin
  sellingRangeMax
  comparableSales {
    ...PropertyAppraisalComparableSaleBase
  }
  marketingFee {
    ...AgentMarketingFeeBase
  }
  commission {
    ...AgentCommissionBase
  }
  appraisalVideo {
    ...UserMediaBase
  }
  additionalNotes
  sentAt
  agent {
    ...AgentBase
  }
  property {
    ...PropertyBase
  }
  appointmentRequestedBySeller
}
    ${PropertyAppraisalComparableSaleBaseFragmentDoc}
${AgentMarketingFeeBaseFragmentDoc}
${AgentCommissionBaseFragmentDoc}
${UserMediaBaseFragmentDoc}
${AgentBaseFragmentDoc}
${PropertyBaseFragmentDoc}`;
export const PropertyAppraisalRequestBaseFragmentDoc = gql`
    fragment PropertyAppraisalRequestBase on PropertyAppraisalRequest {
  id
  state
  property {
    ...PropertyBase
  }
  draftPropertyAppraisal {
    ...DraftPropertyAppraisalBase
  }
  propertyAppraisal {
    ...PropertyAppraisalBase
  }
  requestedAt
  expiresAt
  seenByAgent
  paymentConditionAccepted
}
    ${PropertyBaseFragmentDoc}
${DraftPropertyAppraisalBaseFragmentDoc}
${PropertyAppraisalBaseFragmentDoc}`;
export const PropertyAppraisalRequestEdgeBaseFragmentDoc = gql`
    fragment PropertyAppraisalRequestEdgeBase on PropertyAppraisalRequestEdge {
  cursor
  node {
    ...PropertyAppraisalRequestBase
  }
}
    ${PropertyAppraisalRequestBaseFragmentDoc}`;
export const PropertyWithAppraisalsFragmentDoc = gql`
    fragment PropertyWithAppraisals on Property {
  ...PropertyBase
  propertyAppraisals {
    ...PropertyAppraisalBase
  }
}
    ${PropertyBaseFragmentDoc}
${PropertyAppraisalBaseFragmentDoc}`;
export const UserBaseFragmentDoc = gql`
    fragment UserBase on User {
  id
  email
  userType
  isGhostUser
  isEmailVerified
  mobileNumber
  agentProfile {
    ...AgentBase
  }
  sellerProfile {
    name
    isPrivacyPolicyAccepted
    nextPage
    status
  }
  profileComplete
}
    ${AgentBaseFragmentDoc}`;
export const CancelSuburbSubscriptionDocument = gql`
    mutation cancelSuburbSubscription($suburbSubscriptionId: ID!) {
  cancelSuburbSubscription(suburbSubscriptionId: $suburbSubscriptionId) {
    ...SubscribedSuburbBase
  }
}
    ${SubscribedSuburbBaseFragmentDoc}`;
export type CancelSuburbSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelSuburbSubscriptionMutation, CancelSuburbSubscriptionMutationVariables>;

/**
 * __useCancelSuburbSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSuburbSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSuburbSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSuburbSubscriptionMutation, { data, loading, error }] = useCancelSuburbSubscriptionMutation({
 *   variables: {
 *      suburbSubscriptionId: // value for 'suburbSubscriptionId'
 *   },
 * });
 */
export function useCancelSuburbSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSuburbSubscriptionMutation, CancelSuburbSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSuburbSubscriptionMutation, CancelSuburbSubscriptionMutationVariables>(CancelSuburbSubscriptionDocument, baseOptions);
      }
export type CancelSuburbSubscriptionMutationHookResult = ReturnType<typeof useCancelSuburbSubscriptionMutation>;
export type CancelSuburbSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelSuburbSubscriptionMutation>;
export type CancelSuburbSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSuburbSubscriptionMutation, CancelSuburbSubscriptionMutationVariables>;
export const ConfirmPropertySaleDocument = gql`
    mutation confirmPropertySale($id: ID!, $salePrice: Int!, $contractDate: Date!) {
  confirmPropertySale(id: $id, salePrice: $salePrice, contractDate: $contractDate) {
    ...PropertySaleBase
  }
}
    ${PropertySaleBaseFragmentDoc}`;
export type ConfirmPropertySaleMutationFn = ApolloReactCommon.MutationFunction<ConfirmPropertySaleMutation, ConfirmPropertySaleMutationVariables>;

/**
 * __useConfirmPropertySaleMutation__
 *
 * To run a mutation, you first call `useConfirmPropertySaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPropertySaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPropertySaleMutation, { data, loading, error }] = useConfirmPropertySaleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      salePrice: // value for 'salePrice'
 *      contractDate: // value for 'contractDate'
 *   },
 * });
 */
export function useConfirmPropertySaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmPropertySaleMutation, ConfirmPropertySaleMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmPropertySaleMutation, ConfirmPropertySaleMutationVariables>(ConfirmPropertySaleDocument, baseOptions);
      }
export type ConfirmPropertySaleMutationHookResult = ReturnType<typeof useConfirmPropertySaleMutation>;
export type ConfirmPropertySaleMutationResult = ApolloReactCommon.MutationResult<ConfirmPropertySaleMutation>;
export type ConfirmPropertySaleMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmPropertySaleMutation, ConfirmPropertySaleMutationVariables>;
export const CreateAgentUserDocument = gql`
    mutation createAgentUser($email: String!, $password: String!) {
  createAgentUser(email: $email, password: $password) {
    token
    user {
      ...UserBase
    }
  }
}
    ${UserBaseFragmentDoc}`;
export type CreateAgentUserMutationFn = ApolloReactCommon.MutationFunction<CreateAgentUserMutation, CreateAgentUserMutationVariables>;

/**
 * __useCreateAgentUserMutation__
 *
 * To run a mutation, you first call `useCreateAgentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgentUserMutation, { data, loading, error }] = useCreateAgentUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateAgentUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAgentUserMutation, CreateAgentUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAgentUserMutation, CreateAgentUserMutationVariables>(CreateAgentUserDocument, baseOptions);
      }
export type CreateAgentUserMutationHookResult = ReturnType<typeof useCreateAgentUserMutation>;
export type CreateAgentUserMutationResult = ApolloReactCommon.MutationResult<CreateAgentUserMutation>;
export type CreateAgentUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAgentUserMutation, CreateAgentUserMutationVariables>;
export const GetSuburbFromSearchWithNearbySuburbsDocument = gql`
    query getSuburbFromSearchWithNearbySuburbs($suburbs: [String!]!, $state: String!, $postcode: String) {
  getSuburbFromSearch(suburbs: $suburbs, state: $state, postcode: $postcode) {
    ...SuburbWithAgentStats
    nearbySuburbs {
      ...SuburbWithAgentStats
    }
  }
}
    ${SuburbWithAgentStatsFragmentDoc}`;

/**
 * __useGetSuburbFromSearchWithNearbySuburbsQuery__
 *
 * To run a query within a React component, call `useGetSuburbFromSearchWithNearbySuburbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuburbFromSearchWithNearbySuburbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuburbFromSearchWithNearbySuburbsQuery({
 *   variables: {
 *      suburbs: // value for 'suburbs'
 *      state: // value for 'state'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useGetSuburbFromSearchWithNearbySuburbsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSuburbFromSearchWithNearbySuburbsQuery, GetSuburbFromSearchWithNearbySuburbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuburbFromSearchWithNearbySuburbsQuery, GetSuburbFromSearchWithNearbySuburbsQueryVariables>(GetSuburbFromSearchWithNearbySuburbsDocument, baseOptions);
      }
export function useGetSuburbFromSearchWithNearbySuburbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuburbFromSearchWithNearbySuburbsQuery, GetSuburbFromSearchWithNearbySuburbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuburbFromSearchWithNearbySuburbsQuery, GetSuburbFromSearchWithNearbySuburbsQueryVariables>(GetSuburbFromSearchWithNearbySuburbsDocument, baseOptions);
        }
export type GetSuburbFromSearchWithNearbySuburbsQueryHookResult = ReturnType<typeof useGetSuburbFromSearchWithNearbySuburbsQuery>;
export type GetSuburbFromSearchWithNearbySuburbsLazyQueryHookResult = ReturnType<typeof useGetSuburbFromSearchWithNearbySuburbsLazyQuery>;
export type GetSuburbFromSearchWithNearbySuburbsQueryResult = ApolloReactCommon.QueryResult<GetSuburbFromSearchWithNearbySuburbsQuery, GetSuburbFromSearchWithNearbySuburbsQueryVariables>;
export const MyAgentSuburbSubscriptionFreeTrialStateDocument = gql`
    query myAgentSuburbSubscriptionFreeTrialState {
  myAgentSuburbSubscriptionFreeTrialState {
    numberOfFreeTrialsClaimed
    totalFreeTrialsAllowed
  }
}
    `;

/**
 * __useMyAgentSuburbSubscriptionFreeTrialStateQuery__
 *
 * To run a query within a React component, call `useMyAgentSuburbSubscriptionFreeTrialStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAgentSuburbSubscriptionFreeTrialStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAgentSuburbSubscriptionFreeTrialStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAgentSuburbSubscriptionFreeTrialStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyAgentSuburbSubscriptionFreeTrialStateQuery, MyAgentSuburbSubscriptionFreeTrialStateQueryVariables>) {
        return ApolloReactHooks.useQuery<MyAgentSuburbSubscriptionFreeTrialStateQuery, MyAgentSuburbSubscriptionFreeTrialStateQueryVariables>(MyAgentSuburbSubscriptionFreeTrialStateDocument, baseOptions);
      }
export function useMyAgentSuburbSubscriptionFreeTrialStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyAgentSuburbSubscriptionFreeTrialStateQuery, MyAgentSuburbSubscriptionFreeTrialStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyAgentSuburbSubscriptionFreeTrialStateQuery, MyAgentSuburbSubscriptionFreeTrialStateQueryVariables>(MyAgentSuburbSubscriptionFreeTrialStateDocument, baseOptions);
        }
export type MyAgentSuburbSubscriptionFreeTrialStateQueryHookResult = ReturnType<typeof useMyAgentSuburbSubscriptionFreeTrialStateQuery>;
export type MyAgentSuburbSubscriptionFreeTrialStateLazyQueryHookResult = ReturnType<typeof useMyAgentSuburbSubscriptionFreeTrialStateLazyQuery>;
export type MyAgentSuburbSubscriptionFreeTrialStateQueryResult = ApolloReactCommon.QueryResult<MyAgentSuburbSubscriptionFreeTrialStateQuery, MyAgentSuburbSubscriptionFreeTrialStateQueryVariables>;
export const MyDefaultPaymentMethodDocument = gql`
    query myDefaultPaymentMethod {
  myDefaultPaymentMethod {
    ...PaymentMethodBase
  }
}
    ${PaymentMethodBaseFragmentDoc}`;

/**
 * __useMyDefaultPaymentMethodQuery__
 *
 * To run a query within a React component, call `useMyDefaultPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDefaultPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDefaultPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyDefaultPaymentMethodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyDefaultPaymentMethodQuery, MyDefaultPaymentMethodQueryVariables>) {
        return ApolloReactHooks.useQuery<MyDefaultPaymentMethodQuery, MyDefaultPaymentMethodQueryVariables>(MyDefaultPaymentMethodDocument, baseOptions);
      }
export function useMyDefaultPaymentMethodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyDefaultPaymentMethodQuery, MyDefaultPaymentMethodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyDefaultPaymentMethodQuery, MyDefaultPaymentMethodQueryVariables>(MyDefaultPaymentMethodDocument, baseOptions);
        }
export type MyDefaultPaymentMethodQueryHookResult = ReturnType<typeof useMyDefaultPaymentMethodQuery>;
export type MyDefaultPaymentMethodLazyQueryHookResult = ReturnType<typeof useMyDefaultPaymentMethodLazyQuery>;
export type MyDefaultPaymentMethodQueryResult = ApolloReactCommon.QueryResult<MyDefaultPaymentMethodQuery, MyDefaultPaymentMethodQueryVariables>;
export const MySubscribedSuburbsDocument = gql`
    query mySubscribedSuburbs {
  mySubscribedSuburbs {
    ...SubscribedSuburbBase
  }
}
    ${SubscribedSuburbBaseFragmentDoc}`;

/**
 * __useMySubscribedSuburbsQuery__
 *
 * To run a query within a React component, call `useMySubscribedSuburbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySubscribedSuburbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySubscribedSuburbsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySubscribedSuburbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MySubscribedSuburbsQuery, MySubscribedSuburbsQueryVariables>) {
        return ApolloReactHooks.useQuery<MySubscribedSuburbsQuery, MySubscribedSuburbsQueryVariables>(MySubscribedSuburbsDocument, baseOptions);
      }
export function useMySubscribedSuburbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySubscribedSuburbsQuery, MySubscribedSuburbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MySubscribedSuburbsQuery, MySubscribedSuburbsQueryVariables>(MySubscribedSuburbsDocument, baseOptions);
        }
export type MySubscribedSuburbsQueryHookResult = ReturnType<typeof useMySubscribedSuburbsQuery>;
export type MySubscribedSuburbsLazyQueryHookResult = ReturnType<typeof useMySubscribedSuburbsLazyQuery>;
export type MySubscribedSuburbsQueryResult = ApolloReactCommon.QueryResult<MySubscribedSuburbsQuery, MySubscribedSuburbsQueryVariables>;
export const PropertySaleDocument = gql`
    query propertySale($id: ID!) {
  propertySale(id: $id) {
    ...PropertySaleBase
  }
}
    ${PropertySaleBaseFragmentDoc}`;

/**
 * __usePropertySaleQuery__
 *
 * To run a query within a React component, call `usePropertySaleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertySaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertySaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertySaleQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PropertySaleQuery, PropertySaleQueryVariables>) {
        return ApolloReactHooks.useQuery<PropertySaleQuery, PropertySaleQueryVariables>(PropertySaleDocument, baseOptions);
      }
export function usePropertySaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PropertySaleQuery, PropertySaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PropertySaleQuery, PropertySaleQueryVariables>(PropertySaleDocument, baseOptions);
        }
export type PropertySaleQueryHookResult = ReturnType<typeof usePropertySaleQuery>;
export type PropertySaleLazyQueryHookResult = ReturnType<typeof usePropertySaleLazyQuery>;
export type PropertySaleQueryResult = ApolloReactCommon.QueryResult<PropertySaleQuery, PropertySaleQueryVariables>;
export const RenewSuburbSubscriptionDocument = gql`
    mutation renewSuburbSubscription($suburbSubscriptionId: ID!) {
  renewSuburbSubscription(suburbSubscriptionId: $suburbSubscriptionId) {
    ...SubscribedSuburbBase
  }
}
    ${SubscribedSuburbBaseFragmentDoc}`;
export type RenewSuburbSubscriptionMutationFn = ApolloReactCommon.MutationFunction<RenewSuburbSubscriptionMutation, RenewSuburbSubscriptionMutationVariables>;

/**
 * __useRenewSuburbSubscriptionMutation__
 *
 * To run a mutation, you first call `useRenewSuburbSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewSuburbSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewSuburbSubscriptionMutation, { data, loading, error }] = useRenewSuburbSubscriptionMutation({
 *   variables: {
 *      suburbSubscriptionId: // value for 'suburbSubscriptionId'
 *   },
 * });
 */
export function useRenewSuburbSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenewSuburbSubscriptionMutation, RenewSuburbSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RenewSuburbSubscriptionMutation, RenewSuburbSubscriptionMutationVariables>(RenewSuburbSubscriptionDocument, baseOptions);
      }
export type RenewSuburbSubscriptionMutationHookResult = ReturnType<typeof useRenewSuburbSubscriptionMutation>;
export type RenewSuburbSubscriptionMutationResult = ApolloReactCommon.MutationResult<RenewSuburbSubscriptionMutation>;
export type RenewSuburbSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RenewSuburbSubscriptionMutation, RenewSuburbSubscriptionMutationVariables>;
export const StripeConfirmPaymentMethodDocument = gql`
    mutation stripeConfirmPaymentMethod($stripePaymentMethodId: String!) {
  stripeConfirmPaymentMethod(stripePaymentMethodId: $stripePaymentMethodId) {
    ...PaymentMethodBase
  }
}
    ${PaymentMethodBaseFragmentDoc}`;
export type StripeConfirmPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<StripeConfirmPaymentMethodMutation, StripeConfirmPaymentMethodMutationVariables>;

/**
 * __useStripeConfirmPaymentMethodMutation__
 *
 * To run a mutation, you first call `useStripeConfirmPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeConfirmPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeConfirmPaymentMethodMutation, { data, loading, error }] = useStripeConfirmPaymentMethodMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useStripeConfirmPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StripeConfirmPaymentMethodMutation, StripeConfirmPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<StripeConfirmPaymentMethodMutation, StripeConfirmPaymentMethodMutationVariables>(StripeConfirmPaymentMethodDocument, baseOptions);
      }
export type StripeConfirmPaymentMethodMutationHookResult = ReturnType<typeof useStripeConfirmPaymentMethodMutation>;
export type StripeConfirmPaymentMethodMutationResult = ApolloReactCommon.MutationResult<StripeConfirmPaymentMethodMutation>;
export type StripeConfirmPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<StripeConfirmPaymentMethodMutation, StripeConfirmPaymentMethodMutationVariables>;
export const StripeCreateSetupIntentDocument = gql`
    mutation stripeCreateSetupIntent {
  stripeCreateSetupIntent {
    clientSecret
  }
}
    `;
export type StripeCreateSetupIntentMutationFn = ApolloReactCommon.MutationFunction<StripeCreateSetupIntentMutation, StripeCreateSetupIntentMutationVariables>;

/**
 * __useStripeCreateSetupIntentMutation__
 *
 * To run a mutation, you first call `useStripeCreateSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateSetupIntentMutation, { data, loading, error }] = useStripeCreateSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeCreateSetupIntentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StripeCreateSetupIntentMutation, StripeCreateSetupIntentMutationVariables>) {
        return ApolloReactHooks.useMutation<StripeCreateSetupIntentMutation, StripeCreateSetupIntentMutationVariables>(StripeCreateSetupIntentDocument, baseOptions);
      }
export type StripeCreateSetupIntentMutationHookResult = ReturnType<typeof useStripeCreateSetupIntentMutation>;
export type StripeCreateSetupIntentMutationResult = ApolloReactCommon.MutationResult<StripeCreateSetupIntentMutation>;
export type StripeCreateSetupIntentMutationOptions = ApolloReactCommon.BaseMutationOptions<StripeCreateSetupIntentMutation, StripeCreateSetupIntentMutationVariables>;
export const SubscribeToSuburbsDocument = gql`
    mutation subscribeToSuburbs($suburbIds: [ID!]!) {
  subscribeToSuburbs(suburbIds: $suburbIds) {
    ...SubscribedSuburbBase
  }
}
    ${SubscribedSuburbBaseFragmentDoc}`;
export type SubscribeToSuburbsMutationFn = ApolloReactCommon.MutationFunction<SubscribeToSuburbsMutation, SubscribeToSuburbsMutationVariables>;

/**
 * __useSubscribeToSuburbsMutation__
 *
 * To run a mutation, you first call `useSubscribeToSuburbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSuburbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToSuburbsMutation, { data, loading, error }] = useSubscribeToSuburbsMutation({
 *   variables: {
 *      suburbIds: // value for 'suburbIds'
 *   },
 * });
 */
export function useSubscribeToSuburbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscribeToSuburbsMutation, SubscribeToSuburbsMutationVariables>) {
        return ApolloReactHooks.useMutation<SubscribeToSuburbsMutation, SubscribeToSuburbsMutationVariables>(SubscribeToSuburbsDocument, baseOptions);
      }
export type SubscribeToSuburbsMutationHookResult = ReturnType<typeof useSubscribeToSuburbsMutation>;
export type SubscribeToSuburbsMutationResult = ApolloReactCommon.MutationResult<SubscribeToSuburbsMutation>;
export type SubscribeToSuburbsMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscribeToSuburbsMutation, SubscribeToSuburbsMutationVariables>;
export const SuburbsDocument = gql`
    query suburbs($ids: [ID!]!) {
  suburbs(ids: $ids) {
    ...SuburbBase
  }
}
    ${SuburbBaseFragmentDoc}`;

/**
 * __useSuburbsQuery__
 *
 * To run a query within a React component, call `useSuburbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuburbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuburbsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSuburbsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SuburbsQuery, SuburbsQueryVariables>) {
        return ApolloReactHooks.useQuery<SuburbsQuery, SuburbsQueryVariables>(SuburbsDocument, baseOptions);
      }
export function useSuburbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuburbsQuery, SuburbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuburbsQuery, SuburbsQueryVariables>(SuburbsDocument, baseOptions);
        }
export type SuburbsQueryHookResult = ReturnType<typeof useSuburbsQuery>;
export type SuburbsLazyQueryHookResult = ReturnType<typeof useSuburbsLazyQuery>;
export type SuburbsQueryResult = ApolloReactCommon.QueryResult<SuburbsQuery, SuburbsQueryVariables>;
export const UpdateAgentProfileBasicDetailsDocument = gql`
    mutation updateAgentProfileBasicDetails($name: String!, $mobileNumber: String!) {
  updateAgentProfileBasicDetails(name: $name, mobileNumber: $mobileNumber) {
    ...UserBase
  }
}
    ${UserBaseFragmentDoc}`;
export type UpdateAgentProfileBasicDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateAgentProfileBasicDetailsMutation, UpdateAgentProfileBasicDetailsMutationVariables>;

/**
 * __useUpdateAgentProfileBasicDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAgentProfileBasicDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentProfileBasicDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentProfileBasicDetailsMutation, { data, loading, error }] = useUpdateAgentProfileBasicDetailsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useUpdateAgentProfileBasicDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAgentProfileBasicDetailsMutation, UpdateAgentProfileBasicDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAgentProfileBasicDetailsMutation, UpdateAgentProfileBasicDetailsMutationVariables>(UpdateAgentProfileBasicDetailsDocument, baseOptions);
      }
export type UpdateAgentProfileBasicDetailsMutationHookResult = ReturnType<typeof useUpdateAgentProfileBasicDetailsMutation>;
export type UpdateAgentProfileBasicDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateAgentProfileBasicDetailsMutation>;
export type UpdateAgentProfileBasicDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAgentProfileBasicDetailsMutation, UpdateAgentProfileBasicDetailsMutationVariables>;
export const UpdateAgentProfileDetailsDocument = gql`
    mutation updateAgentProfileDetails($profilePictureUserMediaId: ID, $bio: String!, $agencyLogoUserMediaId: ID!, $agencyName: String!, $agencyBio: String!) {
  updateAgentProfileDetails(
    profilePictureUserMediaId: $profilePictureUserMediaId
    bio: $bio
    agencyLogoUserMediaId: $agencyLogoUserMediaId
    agencyName: $agencyName
    agencyBio: $agencyBio
  ) {
    ...AgentBase
  }
}
    ${AgentBaseFragmentDoc}`;
export type UpdateAgentProfileDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateAgentProfileDetailsMutation, UpdateAgentProfileDetailsMutationVariables>;

/**
 * __useUpdateAgentProfileDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAgentProfileDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentProfileDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentProfileDetailsMutation, { data, loading, error }] = useUpdateAgentProfileDetailsMutation({
 *   variables: {
 *      profilePictureUserMediaId: // value for 'profilePictureUserMediaId'
 *      bio: // value for 'bio'
 *      agencyLogoUserMediaId: // value for 'agencyLogoUserMediaId'
 *      agencyName: // value for 'agencyName'
 *      agencyBio: // value for 'agencyBio'
 *   },
 * });
 */
export function useUpdateAgentProfileDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAgentProfileDetailsMutation, UpdateAgentProfileDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAgentProfileDetailsMutation, UpdateAgentProfileDetailsMutationVariables>(UpdateAgentProfileDetailsDocument, baseOptions);
      }
export type UpdateAgentProfileDetailsMutationHookResult = ReturnType<typeof useUpdateAgentProfileDetailsMutation>;
export type UpdateAgentProfileDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateAgentProfileDetailsMutation>;
export type UpdateAgentProfileDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAgentProfileDetailsMutation, UpdateAgentProfileDetailsMutationVariables>;
export const SendContinueEmailDocument = gql`
    mutation sendContinueEmail($email: String!) {
  sendContinueEmail(email: $email) {
    message
  }
}
    `;
export type SendContinueEmailMutationFn = ApolloReactCommon.MutationFunction<SendContinueEmailMutation, SendContinueEmailMutationVariables>;

/**
 * __useSendContinueEmailMutation__
 *
 * To run a mutation, you first call `useSendContinueEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContinueEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContinueEmailMutation, { data, loading, error }] = useSendContinueEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendContinueEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendContinueEmailMutation, SendContinueEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendContinueEmailMutation, SendContinueEmailMutationVariables>(SendContinueEmailDocument, baseOptions);
      }
export type SendContinueEmailMutationHookResult = ReturnType<typeof useSendContinueEmailMutation>;
export type SendContinueEmailMutationResult = ApolloReactCommon.MutationResult<SendContinueEmailMutation>;
export type SendContinueEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendContinueEmailMutation, SendContinueEmailMutationVariables>;
export const GetUserByTokenDocument = gql`
    mutation getUserByToken($continueToken: String!) {
  getUserByToken(continueToken: $continueToken) {
    token
    user {
      ...UserBase
    }
  }
}
    ${UserBaseFragmentDoc}`;
export type GetUserByTokenMutationFn = ApolloReactCommon.MutationFunction<GetUserByTokenMutation, GetUserByTokenMutationVariables>;

/**
 * __useGetUserByTokenMutation__
 *
 * To run a mutation, you first call `useGetUserByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserByTokenMutation, { data, loading, error }] = useGetUserByTokenMutation({
 *   variables: {
 *      continueToken: // value for 'continueToken'
 *   },
 * });
 */
export function useGetUserByTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetUserByTokenMutation, GetUserByTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<GetUserByTokenMutation, GetUserByTokenMutationVariables>(GetUserByTokenDocument, baseOptions);
      }
export type GetUserByTokenMutationHookResult = ReturnType<typeof useGetUserByTokenMutation>;
export type GetUserByTokenMutationResult = ApolloReactCommon.MutationResult<GetUserByTokenMutation>;
export type GetUserByTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<GetUserByTokenMutation, GetUserByTokenMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      ...UserBase
    }
  }
}
    ${UserBaseFragmentDoc}`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserBase
  }
}
    ${UserBaseFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($userId: ID) {
  resendVerificationEmail(userId: $userId) {
    message
  }
}
    `;
export type ResendVerificationEmailMutationFn = ApolloReactCommon.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, baseOptions);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = ApolloReactCommon.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation sendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email)
}
    `;
export type SendPasswordResetEmailMutationFn = ApolloReactCommon.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, baseOptions);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = ApolloReactCommon.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const UpdateUserDetailsDocument = gql`
    mutation updateUserDetails($name: String, $mobileNumber: String, $email: String, $socialEmail: String, $isPrivacyPolicyAccepted: Boolean, $nextPage: String) {
  updateUserDetails(
    name: $name
    mobileNumber: $mobileNumber
    email: $email
    socialEmail: $socialEmail
    isPrivacyPolicyAccepted: $isPrivacyPolicyAccepted
    nextPage: $nextPage
  ) {
    ...UserBase
  }
}
    ${UserBaseFragmentDoc}`;
export type UpdateUserDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;

/**
 * __useUpdateUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailsMutation, { data, loading, error }] = useUpdateUserDetailsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      mobileNumber: // value for 'mobileNumber'
 *      email: // value for 'email'
 *      socialEmail: // value for 'socialEmail'
 *      isPrivacyPolicyAccepted: // value for 'isPrivacyPolicyAccepted'
 *      nextPage: // value for 'nextPage'
 *   },
 * });
 */
export function useUpdateUserDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>(UpdateUserDetailsDocument, baseOptions);
      }
export type UpdateUserDetailsMutationHookResult = ReturnType<typeof useUpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($password: String!, $mobileNumber: String) {
  updateUserPassword(password: $password, mobileNumber: $mobileNumber) {
    ...UserBase
  }
}
    ${UserBaseFragmentDoc}`;
export type UpdateUserPasswordMutationFn = ApolloReactCommon.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, baseOptions);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = ApolloReactCommon.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const UpdateUserPasswordWithResetTokenDocument = gql`
    mutation updateUserPasswordWithResetToken($userId: ID!, $passwordResetToken: String!, $password: String!) {
  updateUserPasswordWithResetToken(
    userId: $userId
    passwordResetToken: $passwordResetToken
    password: $password
  ) {
    ...UserBase
  }
}
    ${UserBaseFragmentDoc}`;
export type UpdateUserPasswordWithResetTokenMutationFn = ApolloReactCommon.MutationFunction<UpdateUserPasswordWithResetTokenMutation, UpdateUserPasswordWithResetTokenMutationVariables>;

/**
 * __useUpdateUserPasswordWithResetTokenMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordWithResetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordWithResetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordWithResetTokenMutation, { data, loading, error }] = useUpdateUserPasswordWithResetTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      passwordResetToken: // value for 'passwordResetToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserPasswordWithResetTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserPasswordWithResetTokenMutation, UpdateUserPasswordWithResetTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserPasswordWithResetTokenMutation, UpdateUserPasswordWithResetTokenMutationVariables>(UpdateUserPasswordWithResetTokenDocument, baseOptions);
      }
export type UpdateUserPasswordWithResetTokenMutationHookResult = ReturnType<typeof useUpdateUserPasswordWithResetTokenMutation>;
export type UpdateUserPasswordWithResetTokenMutationResult = ApolloReactCommon.MutationResult<UpdateUserPasswordWithResetTokenMutation>;
export type UpdateUserPasswordWithResetTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserPasswordWithResetTokenMutation, UpdateUserPasswordWithResetTokenMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($emailVerificationToken: String!) {
  verifyEmail(emailVerificationToken: $emailVerificationToken) {
    message
  }
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      emailVerificationToken: // value for 'emailVerificationToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const AgentsForPropertyDocument = gql`
    query agentsForProperty($propertyId: ID!) {
  agentsForProperty(propertyId: $propertyId) {
    ...AgentBase
  }
}
    ${AgentBaseFragmentDoc}`;

/**
 * __useAgentsForPropertyQuery__
 *
 * To run a query within a React component, call `useAgentsForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsForPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useAgentsForPropertyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AgentsForPropertyQuery, AgentsForPropertyQueryVariables>) {
        return ApolloReactHooks.useQuery<AgentsForPropertyQuery, AgentsForPropertyQueryVariables>(AgentsForPropertyDocument, baseOptions);
      }
export function useAgentsForPropertyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgentsForPropertyQuery, AgentsForPropertyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgentsForPropertyQuery, AgentsForPropertyQueryVariables>(AgentsForPropertyDocument, baseOptions);
        }
export type AgentsForPropertyQueryHookResult = ReturnType<typeof useAgentsForPropertyQuery>;
export type AgentsForPropertyLazyQueryHookResult = ReturnType<typeof useAgentsForPropertyLazyQuery>;
export type AgentsForPropertyQueryResult = ApolloReactCommon.QueryResult<AgentsForPropertyQuery, AgentsForPropertyQueryVariables>;
export const AttachUserMediaToDraftPropertyDocument = gql`
    mutation attachUserMediaToDraftProperty($propertyId: ID!, $propertyMediaType: PropertyMediaType!, $userMediaId: ID!) {
  attachUserMediaToDraftProperty(
    propertyId: $propertyId
    propertyMediaType: $propertyMediaType
    userMediaId: $userMediaId
  ) {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;
export type AttachUserMediaToDraftPropertyMutationFn = ApolloReactCommon.MutationFunction<AttachUserMediaToDraftPropertyMutation, AttachUserMediaToDraftPropertyMutationVariables>;

/**
 * __useAttachUserMediaToDraftPropertyMutation__
 *
 * To run a mutation, you first call `useAttachUserMediaToDraftPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachUserMediaToDraftPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachUserMediaToDraftPropertyMutation, { data, loading, error }] = useAttachUserMediaToDraftPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      propertyMediaType: // value for 'propertyMediaType'
 *      userMediaId: // value for 'userMediaId'
 *   },
 * });
 */
export function useAttachUserMediaToDraftPropertyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachUserMediaToDraftPropertyMutation, AttachUserMediaToDraftPropertyMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachUserMediaToDraftPropertyMutation, AttachUserMediaToDraftPropertyMutationVariables>(AttachUserMediaToDraftPropertyDocument, baseOptions);
      }
export type AttachUserMediaToDraftPropertyMutationHookResult = ReturnType<typeof useAttachUserMediaToDraftPropertyMutation>;
export type AttachUserMediaToDraftPropertyMutationResult = ApolloReactCommon.MutationResult<AttachUserMediaToDraftPropertyMutation>;
export type AttachUserMediaToDraftPropertyMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachUserMediaToDraftPropertyMutation, AttachUserMediaToDraftPropertyMutationVariables>;
export const CreateGhostUserDocument = gql`
    mutation createGhostUser {
  createGhostUser {
    token
    user {
      ...UserBase
    }
  }
}
    ${UserBaseFragmentDoc}`;
export type CreateGhostUserMutationFn = ApolloReactCommon.MutationFunction<CreateGhostUserMutation, CreateGhostUserMutationVariables>;

/**
 * __useCreateGhostUserMutation__
 *
 * To run a mutation, you first call `useCreateGhostUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGhostUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGhostUserMutation, { data, loading, error }] = useCreateGhostUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateGhostUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGhostUserMutation, CreateGhostUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGhostUserMutation, CreateGhostUserMutationVariables>(CreateGhostUserDocument, baseOptions);
      }
export type CreateGhostUserMutationHookResult = ReturnType<typeof useCreateGhostUserMutation>;
export type CreateGhostUserMutationResult = ApolloReactCommon.MutationResult<CreateGhostUserMutation>;
export type CreateGhostUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGhostUserMutation, CreateGhostUserMutationVariables>;
export const CreateUserMediaDocument = gql`
    mutation createUserMedia($fileExtension: String!, $mimeType: String!) {
  createUserMedia(fileExtension: $fileExtension, mimeType: $mimeType) {
    uploadUrl
    userMedia {
      ...UserMediaBase
    }
  }
}
    ${UserMediaBaseFragmentDoc}`;
export type CreateUserMediaMutationFn = ApolloReactCommon.MutationFunction<CreateUserMediaMutation, CreateUserMediaMutationVariables>;

/**
 * __useCreateUserMediaMutation__
 *
 * To run a mutation, you first call `useCreateUserMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMediaMutation, { data, loading, error }] = useCreateUserMediaMutation({
 *   variables: {
 *      fileExtension: // value for 'fileExtension'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useCreateUserMediaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMediaMutation, CreateUserMediaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMediaMutation, CreateUserMediaMutationVariables>(CreateUserMediaDocument, baseOptions);
      }
export type CreateUserMediaMutationHookResult = ReturnType<typeof useCreateUserMediaMutation>;
export type CreateUserMediaMutationResult = ApolloReactCommon.MutationResult<CreateUserMediaMutation>;
export type CreateUserMediaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMediaMutation, CreateUserMediaMutationVariables>;
export const CurrentDraftPropertyDocument = gql`
    query currentDraftProperty {
  currentDraftProperty {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;

/**
 * __useCurrentDraftPropertyQuery__
 *
 * To run a query within a React component, call `useCurrentDraftPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentDraftPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentDraftPropertyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentDraftPropertyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentDraftPropertyQuery, CurrentDraftPropertyQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentDraftPropertyQuery, CurrentDraftPropertyQueryVariables>(CurrentDraftPropertyDocument, baseOptions);
      }
export function useCurrentDraftPropertyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentDraftPropertyQuery, CurrentDraftPropertyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentDraftPropertyQuery, CurrentDraftPropertyQueryVariables>(CurrentDraftPropertyDocument, baseOptions);
        }
export type CurrentDraftPropertyQueryHookResult = ReturnType<typeof useCurrentDraftPropertyQuery>;
export type CurrentDraftPropertyLazyQueryHookResult = ReturnType<typeof useCurrentDraftPropertyLazyQuery>;
export type CurrentDraftPropertyQueryResult = ApolloReactCommon.QueryResult<CurrentDraftPropertyQuery, CurrentDraftPropertyQueryVariables>;
export const GetSuburbFromSearchDocument = gql`
    query getSuburbFromSearch($suburbs: [String!]!, $state: String!, $postcode: String) {
  getSuburbFromSearch(suburbs: $suburbs, state: $state, postcode: $postcode) {
    ...SuburbBase
  }
}
    ${SuburbBaseFragmentDoc}`;

/**
 * __useGetSuburbFromSearchQuery__
 *
 * To run a query within a React component, call `useGetSuburbFromSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuburbFromSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuburbFromSearchQuery({
 *   variables: {
 *      suburbs: // value for 'suburbs'
 *      state: // value for 'state'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useGetSuburbFromSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSuburbFromSearchQuery, GetSuburbFromSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuburbFromSearchQuery, GetSuburbFromSearchQueryVariables>(GetSuburbFromSearchDocument, baseOptions);
      }
export function useGetSuburbFromSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuburbFromSearchQuery, GetSuburbFromSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuburbFromSearchQuery, GetSuburbFromSearchQueryVariables>(GetSuburbFromSearchDocument, baseOptions);
        }
export type GetSuburbFromSearchQueryHookResult = ReturnType<typeof useGetSuburbFromSearchQuery>;
export type GetSuburbFromSearchLazyQueryHookResult = ReturnType<typeof useGetSuburbFromSearchLazyQuery>;
export type GetSuburbFromSearchQueryResult = ApolloReactCommon.QueryResult<GetSuburbFromSearchQuery, GetSuburbFromSearchQueryVariables>;
export const PublishPropertyDocument = gql`
    mutation publishProperty($propertyId: ID!, $selectedAgents: [ID!]!, $askAgentSpotForMoreAgents: Boolean!) {
  publishProperty(
    propertyId: $propertyId
    selectedAgents: $selectedAgents
    askAgentSpotForMoreAgents: $askAgentSpotForMoreAgents
  ) {
    ...PropertyBase
  }
}
    ${PropertyBaseFragmentDoc}`;
export type PublishPropertyMutationFn = ApolloReactCommon.MutationFunction<PublishPropertyMutation, PublishPropertyMutationVariables>;

/**
 * __usePublishPropertyMutation__
 *
 * To run a mutation, you first call `usePublishPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPropertyMutation, { data, loading, error }] = usePublishPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      selectedAgents: // value for 'selectedAgents'
 *      askAgentSpotForMoreAgents: // value for 'askAgentSpotForMoreAgents'
 *   },
 * });
 */
export function usePublishPropertyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishPropertyMutation, PublishPropertyMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishPropertyMutation, PublishPropertyMutationVariables>(PublishPropertyDocument, baseOptions);
      }
export type PublishPropertyMutationHookResult = ReturnType<typeof usePublishPropertyMutation>;
export type PublishPropertyMutationResult = ApolloReactCommon.MutationResult<PublishPropertyMutation>;
export type PublishPropertyMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishPropertyMutation, PublishPropertyMutationVariables>;
export const RemovePropertyMediaFromDraftPropertyDocument = gql`
    mutation removePropertyMediaFromDraftProperty($propertyMediaId: ID!) {
  removePropertyMediaFromDraftProperty(propertyMediaId: $propertyMediaId) {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;
export type RemovePropertyMediaFromDraftPropertyMutationFn = ApolloReactCommon.MutationFunction<RemovePropertyMediaFromDraftPropertyMutation, RemovePropertyMediaFromDraftPropertyMutationVariables>;

/**
 * __useRemovePropertyMediaFromDraftPropertyMutation__
 *
 * To run a mutation, you first call `useRemovePropertyMediaFromDraftPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePropertyMediaFromDraftPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePropertyMediaFromDraftPropertyMutation, { data, loading, error }] = useRemovePropertyMediaFromDraftPropertyMutation({
 *   variables: {
 *      propertyMediaId: // value for 'propertyMediaId'
 *   },
 * });
 */
export function useRemovePropertyMediaFromDraftPropertyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePropertyMediaFromDraftPropertyMutation, RemovePropertyMediaFromDraftPropertyMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePropertyMediaFromDraftPropertyMutation, RemovePropertyMediaFromDraftPropertyMutationVariables>(RemovePropertyMediaFromDraftPropertyDocument, baseOptions);
      }
export type RemovePropertyMediaFromDraftPropertyMutationHookResult = ReturnType<typeof useRemovePropertyMediaFromDraftPropertyMutation>;
export type RemovePropertyMediaFromDraftPropertyMutationResult = ApolloReactCommon.MutationResult<RemovePropertyMediaFromDraftPropertyMutation>;
export type RemovePropertyMediaFromDraftPropertyMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePropertyMediaFromDraftPropertyMutation, RemovePropertyMediaFromDraftPropertyMutationVariables>;
export const StartNewDraftPropertyDocument = gql`
    mutation startNewDraftProperty($streetName: String!, $suburbId: ID!) {
  startNewDraftProperty(
    propertyType: HOUSE
    streetName: $streetName
    suburbId: $suburbId
  ) {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;
export type StartNewDraftPropertyMutationFn = ApolloReactCommon.MutationFunction<StartNewDraftPropertyMutation, StartNewDraftPropertyMutationVariables>;

/**
 * __useStartNewDraftPropertyMutation__
 *
 * To run a mutation, you first call `useStartNewDraftPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartNewDraftPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startNewDraftPropertyMutation, { data, loading, error }] = useStartNewDraftPropertyMutation({
 *   variables: {
 *      streetName: // value for 'streetName'
 *      suburbId: // value for 'suburbId'
 *   },
 * });
 */
export function useStartNewDraftPropertyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartNewDraftPropertyMutation, StartNewDraftPropertyMutationVariables>) {
        return ApolloReactHooks.useMutation<StartNewDraftPropertyMutation, StartNewDraftPropertyMutationVariables>(StartNewDraftPropertyDocument, baseOptions);
      }
export type StartNewDraftPropertyMutationHookResult = ReturnType<typeof useStartNewDraftPropertyMutation>;
export type StartNewDraftPropertyMutationResult = ApolloReactCommon.MutationResult<StartNewDraftPropertyMutation>;
export type StartNewDraftPropertyMutationOptions = ApolloReactCommon.BaseMutationOptions<StartNewDraftPropertyMutation, StartNewDraftPropertyMutationVariables>;
export const UpdatePropertyDetailsDocument = gql`
    mutation updatePropertyDetails($propertyId: ID!, $propertyDetails: PropertyDetailsInput!) {
  updatePropertyDetails(
    propertyId: $propertyId
    propertyDetails: $propertyDetails
  ) {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;
export type UpdatePropertyDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdatePropertyDetailsMutation, UpdatePropertyDetailsMutationVariables>;

/**
 * __useUpdatePropertyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyDetailsMutation, { data, loading, error }] = useUpdatePropertyDetailsMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      propertyDetails: // value for 'propertyDetails'
 *   },
 * });
 */
export function useUpdatePropertyDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePropertyDetailsMutation, UpdatePropertyDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePropertyDetailsMutation, UpdatePropertyDetailsMutationVariables>(UpdatePropertyDetailsDocument, baseOptions);
      }
export type UpdatePropertyDetailsMutationHookResult = ReturnType<typeof useUpdatePropertyDetailsMutation>;
export type UpdatePropertyDetailsMutationResult = ApolloReactCommon.MutationResult<UpdatePropertyDetailsMutation>;
export type UpdatePropertyDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePropertyDetailsMutation, UpdatePropertyDetailsMutationVariables>;
export const UpdatePropertySaleInformationDocument = gql`
    mutation updatePropertySaleInformation($propertyId: ID!, $propertySaleInformation: PropertySaleInformationInput!) {
  updatePropertySaleInformation(
    propertyId: $propertyId
    propertySaleInformation: $propertySaleInformation
  ) {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;
export type UpdatePropertySaleInformationMutationFn = ApolloReactCommon.MutationFunction<UpdatePropertySaleInformationMutation, UpdatePropertySaleInformationMutationVariables>;

/**
 * __useUpdatePropertySaleInformationMutation__
 *
 * To run a mutation, you first call `useUpdatePropertySaleInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertySaleInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertySaleInformationMutation, { data, loading, error }] = useUpdatePropertySaleInformationMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      propertySaleInformation: // value for 'propertySaleInformation'
 *   },
 * });
 */
export function useUpdatePropertySaleInformationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePropertySaleInformationMutation, UpdatePropertySaleInformationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePropertySaleInformationMutation, UpdatePropertySaleInformationMutationVariables>(UpdatePropertySaleInformationDocument, baseOptions);
      }
export type UpdatePropertySaleInformationMutationHookResult = ReturnType<typeof useUpdatePropertySaleInformationMutation>;
export type UpdatePropertySaleInformationMutationResult = ApolloReactCommon.MutationResult<UpdatePropertySaleInformationMutation>;
export type UpdatePropertySaleInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePropertySaleInformationMutation, UpdatePropertySaleInformationMutationVariables>;
export const UpdatePropertyTypeDocument = gql`
    mutation updatePropertyType($propertyId: ID!, $propertyType: PropertyType!) {
  updatePropertyType(propertyId: $propertyId, propertyType: $propertyType) {
    ...DraftPropertyBase
  }
}
    ${DraftPropertyBaseFragmentDoc}`;
export type UpdatePropertyTypeMutationFn = ApolloReactCommon.MutationFunction<UpdatePropertyTypeMutation, UpdatePropertyTypeMutationVariables>;

/**
 * __useUpdatePropertyTypeMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyTypeMutation, { data, loading, error }] = useUpdatePropertyTypeMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      propertyType: // value for 'propertyType'
 *   },
 * });
 */
export function useUpdatePropertyTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePropertyTypeMutation, UpdatePropertyTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePropertyTypeMutation, UpdatePropertyTypeMutationVariables>(UpdatePropertyTypeDocument, baseOptions);
      }
export type UpdatePropertyTypeMutationHookResult = ReturnType<typeof useUpdatePropertyTypeMutation>;
export type UpdatePropertyTypeMutationResult = ApolloReactCommon.MutationResult<UpdatePropertyTypeMutation>;
export type UpdatePropertyTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePropertyTypeMutation, UpdatePropertyTypeMutationVariables>;
export const UserMediaDocument = gql`
    query userMedia($id: ID!) {
  userMedia(id: $id) {
    ...UserMediaBase
  }
}
    ${UserMediaBaseFragmentDoc}`;

/**
 * __useUserMediaQuery__
 *
 * To run a query within a React component, call `useUserMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserMediaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserMediaQuery, UserMediaQueryVariables>) {
        return ApolloReactHooks.useQuery<UserMediaQuery, UserMediaQueryVariables>(UserMediaDocument, baseOptions);
      }
export function useUserMediaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserMediaQuery, UserMediaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserMediaQuery, UserMediaQueryVariables>(UserMediaDocument, baseOptions);
        }
export type UserMediaQueryHookResult = ReturnType<typeof useUserMediaQuery>;
export type UserMediaLazyQueryHookResult = ReturnType<typeof useUserMediaLazyQuery>;
export type UserMediaQueryResult = ApolloReactCommon.QueryResult<UserMediaQuery, UserMediaQueryVariables>;
export const AcceptPaymentConditionDocument = gql`
    mutation acceptPaymentCondition($id: ID!) {
  acceptPaymentCondition(id: $id) {
    ...PropertyAppraisalRequestBase
  }
}
    ${PropertyAppraisalRequestBaseFragmentDoc}`;
export type AcceptPaymentConditionMutationFn = ApolloReactCommon.MutationFunction<AcceptPaymentConditionMutation, AcceptPaymentConditionMutationVariables>;

/**
 * __useAcceptPaymentConditionMutation__
 *
 * To run a mutation, you first call `useAcceptPaymentConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPaymentConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPaymentConditionMutation, { data, loading, error }] = useAcceptPaymentConditionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptPaymentConditionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptPaymentConditionMutation, AcceptPaymentConditionMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptPaymentConditionMutation, AcceptPaymentConditionMutationVariables>(AcceptPaymentConditionDocument, baseOptions);
      }
export type AcceptPaymentConditionMutationHookResult = ReturnType<typeof useAcceptPaymentConditionMutation>;
export type AcceptPaymentConditionMutationResult = ApolloReactCommon.MutationResult<AcceptPaymentConditionMutation>;
export type AcceptPaymentConditionMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptPaymentConditionMutation, AcceptPaymentConditionMutationVariables>;
export const AppointmentRequestSeenByAgentDocument = gql`
    mutation appointmentRequestSeenByAgent($id: ID!) {
  appointmentRequestSeenByAgent(id: $id) {
    ...AppointmentRequestBase
  }
}
    ${AppointmentRequestBaseFragmentDoc}`;
export type AppointmentRequestSeenByAgentMutationFn = ApolloReactCommon.MutationFunction<AppointmentRequestSeenByAgentMutation, AppointmentRequestSeenByAgentMutationVariables>;

/**
 * __useAppointmentRequestSeenByAgentMutation__
 *
 * To run a mutation, you first call `useAppointmentRequestSeenByAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentRequestSeenByAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentRequestSeenByAgentMutation, { data, loading, error }] = useAppointmentRequestSeenByAgentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentRequestSeenByAgentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppointmentRequestSeenByAgentMutation, AppointmentRequestSeenByAgentMutationVariables>) {
        return ApolloReactHooks.useMutation<AppointmentRequestSeenByAgentMutation, AppointmentRequestSeenByAgentMutationVariables>(AppointmentRequestSeenByAgentDocument, baseOptions);
      }
export type AppointmentRequestSeenByAgentMutationHookResult = ReturnType<typeof useAppointmentRequestSeenByAgentMutation>;
export type AppointmentRequestSeenByAgentMutationResult = ApolloReactCommon.MutationResult<AppointmentRequestSeenByAgentMutation>;
export type AppointmentRequestSeenByAgentMutationOptions = ApolloReactCommon.BaseMutationOptions<AppointmentRequestSeenByAgentMutation, AppointmentRequestSeenByAgentMutationVariables>;
export const MyAppointmentRequestsDocument = gql`
    query myAppointmentRequests($first: Int!, $after: String) {
  myAppointmentRequests(first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      ...AppointmentRequestEdgeBase
    }
  }
}
    ${AppointmentRequestEdgeBaseFragmentDoc}`;

/**
 * __useMyAppointmentRequestsQuery__
 *
 * To run a query within a React component, call `useMyAppointmentRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAppointmentRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAppointmentRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMyAppointmentRequestsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MyAppointmentRequestsQuery, MyAppointmentRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyAppointmentRequestsQuery, MyAppointmentRequestsQueryVariables>(MyAppointmentRequestsDocument, baseOptions);
      }
export function useMyAppointmentRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyAppointmentRequestsQuery, MyAppointmentRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyAppointmentRequestsQuery, MyAppointmentRequestsQueryVariables>(MyAppointmentRequestsDocument, baseOptions);
        }
export type MyAppointmentRequestsQueryHookResult = ReturnType<typeof useMyAppointmentRequestsQuery>;
export type MyAppointmentRequestsLazyQueryHookResult = ReturnType<typeof useMyAppointmentRequestsLazyQuery>;
export type MyAppointmentRequestsQueryResult = ApolloReactCommon.QueryResult<MyAppointmentRequestsQuery, MyAppointmentRequestsQueryVariables>;
export const UnreadAppointmentRequestsCountDocument = gql`
    query unreadAppointmentRequestsCount {
  unreadAppointmentRequestsCount
}
    `;

/**
 * __useUnreadAppointmentRequestsCountQuery__
 *
 * To run a query within a React component, call `useUnreadAppointmentRequestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadAppointmentRequestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadAppointmentRequestsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadAppointmentRequestsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnreadAppointmentRequestsCountQuery, UnreadAppointmentRequestsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<UnreadAppointmentRequestsCountQuery, UnreadAppointmentRequestsCountQueryVariables>(UnreadAppointmentRequestsCountDocument, baseOptions);
      }
export function useUnreadAppointmentRequestsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnreadAppointmentRequestsCountQuery, UnreadAppointmentRequestsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnreadAppointmentRequestsCountQuery, UnreadAppointmentRequestsCountQueryVariables>(UnreadAppointmentRequestsCountDocument, baseOptions);
        }
export type UnreadAppointmentRequestsCountQueryHookResult = ReturnType<typeof useUnreadAppointmentRequestsCountQuery>;
export type UnreadAppointmentRequestsCountLazyQueryHookResult = ReturnType<typeof useUnreadAppointmentRequestsCountLazyQuery>;
export type UnreadAppointmentRequestsCountQueryResult = ApolloReactCommon.QueryResult<UnreadAppointmentRequestsCountQuery, UnreadAppointmentRequestsCountQueryVariables>;
export const MyCompleteAppraisalRequestsDocument = gql`
    query myCompleteAppraisalRequests($first: Int!, $after: String) {
  myCompleteAppraisalRequests(first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      ...PropertyAppraisalRequestEdgeBase
    }
  }
}
    ${PropertyAppraisalRequestEdgeBaseFragmentDoc}`;

/**
 * __useMyCompleteAppraisalRequestsQuery__
 *
 * To run a query within a React component, call `useMyCompleteAppraisalRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompleteAppraisalRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompleteAppraisalRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMyCompleteAppraisalRequestsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MyCompleteAppraisalRequestsQuery, MyCompleteAppraisalRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyCompleteAppraisalRequestsQuery, MyCompleteAppraisalRequestsQueryVariables>(MyCompleteAppraisalRequestsDocument, baseOptions);
      }
export function useMyCompleteAppraisalRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyCompleteAppraisalRequestsQuery, MyCompleteAppraisalRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyCompleteAppraisalRequestsQuery, MyCompleteAppraisalRequestsQueryVariables>(MyCompleteAppraisalRequestsDocument, baseOptions);
        }
export type MyCompleteAppraisalRequestsQueryHookResult = ReturnType<typeof useMyCompleteAppraisalRequestsQuery>;
export type MyCompleteAppraisalRequestsLazyQueryHookResult = ReturnType<typeof useMyCompleteAppraisalRequestsLazyQuery>;
export type MyCompleteAppraisalRequestsQueryResult = ApolloReactCommon.QueryResult<MyCompleteAppraisalRequestsQuery, MyCompleteAppraisalRequestsQueryVariables>;
export const MyPendingAppraisalRequestsDocument = gql`
    query myPendingAppraisalRequests($first: Int!, $after: String) {
  myPendingAppraisalRequests(first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      ...PropertyAppraisalRequestEdgeBase
    }
  }
}
    ${PropertyAppraisalRequestEdgeBaseFragmentDoc}`;

/**
 * __useMyPendingAppraisalRequestsQuery__
 *
 * To run a query within a React component, call `useMyPendingAppraisalRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPendingAppraisalRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPendingAppraisalRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMyPendingAppraisalRequestsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MyPendingAppraisalRequestsQuery, MyPendingAppraisalRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyPendingAppraisalRequestsQuery, MyPendingAppraisalRequestsQueryVariables>(MyPendingAppraisalRequestsDocument, baseOptions);
      }
export function useMyPendingAppraisalRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyPendingAppraisalRequestsQuery, MyPendingAppraisalRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyPendingAppraisalRequestsQuery, MyPendingAppraisalRequestsQueryVariables>(MyPendingAppraisalRequestsDocument, baseOptions);
        }
export type MyPendingAppraisalRequestsQueryHookResult = ReturnType<typeof useMyPendingAppraisalRequestsQuery>;
export type MyPendingAppraisalRequestsLazyQueryHookResult = ReturnType<typeof useMyPendingAppraisalRequestsLazyQuery>;
export type MyPendingAppraisalRequestsQueryResult = ApolloReactCommon.QueryResult<MyPendingAppraisalRequestsQuery, MyPendingAppraisalRequestsQueryVariables>;
export const MyPropertiesDocument = gql`
    query myProperties($first: Int!, $after: String) {
  myProperties(first: $first, after: $after) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        ...PropertyWithAppraisals
      }
    }
  }
}
    ${PropertyWithAppraisalsFragmentDoc}`;

/**
 * __useMyPropertiesQuery__
 *
 * To run a query within a React component, call `useMyPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPropertiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMyPropertiesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MyPropertiesQuery, MyPropertiesQueryVariables>) {
        return ApolloReactHooks.useQuery<MyPropertiesQuery, MyPropertiesQueryVariables>(MyPropertiesDocument, baseOptions);
      }
export function useMyPropertiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyPropertiesQuery, MyPropertiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyPropertiesQuery, MyPropertiesQueryVariables>(MyPropertiesDocument, baseOptions);
        }
export type MyPropertiesQueryHookResult = ReturnType<typeof useMyPropertiesQuery>;
export type MyPropertiesLazyQueryHookResult = ReturnType<typeof useMyPropertiesLazyQuery>;
export type MyPropertiesQueryResult = ApolloReactCommon.QueryResult<MyPropertiesQuery, MyPropertiesQueryVariables>;
export const NewPropertyDocument = gql`
    query newProperty($id: ID!) {
  property(id: $id) {
    ...PropertyBase
  }
}
    ${PropertyBaseFragmentDoc}`;

/**
 * __useNewPropertyQuery__
 *
 * To run a query within a React component, call `useNewPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewPropertyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NewPropertyQuery, NewPropertyQueryVariables>) {
        return ApolloReactHooks.useQuery<NewPropertyQuery, NewPropertyQueryVariables>(NewPropertyDocument, baseOptions);
      }
export function useNewPropertyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewPropertyQuery, NewPropertyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewPropertyQuery, NewPropertyQueryVariables>(NewPropertyDocument, baseOptions);
        }
export type NewPropertyQueryHookResult = ReturnType<typeof useNewPropertyQuery>;
export type NewPropertyLazyQueryHookResult = ReturnType<typeof useNewPropertyLazyQuery>;
export type NewPropertyQueryResult = ApolloReactCommon.QueryResult<NewPropertyQuery, NewPropertyQueryVariables>;
export const PropertyDocument = gql`
    query property($id: ID!) {
  property(id: $id) {
    ...PropertyWithAppraisals
  }
}
    ${PropertyWithAppraisalsFragmentDoc}`;

/**
 * __usePropertyQuery__
 *
 * To run a query within a React component, call `usePropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PropertyQuery, PropertyQueryVariables>) {
        return ApolloReactHooks.useQuery<PropertyQuery, PropertyQueryVariables>(PropertyDocument, baseOptions);
      }
export function usePropertyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PropertyQuery, PropertyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PropertyQuery, PropertyQueryVariables>(PropertyDocument, baseOptions);
        }
export type PropertyQueryHookResult = ReturnType<typeof usePropertyQuery>;
export type PropertyLazyQueryHookResult = ReturnType<typeof usePropertyLazyQuery>;
export type PropertyQueryResult = ApolloReactCommon.QueryResult<PropertyQuery, PropertyQueryVariables>;
export const PropertyAppraisalDocument = gql`
    query propertyAppraisal($id: ID!) {
  propertyAppraisal(id: $id) {
    ...PropertyAppraisalBase
  }
}
    ${PropertyAppraisalBaseFragmentDoc}`;

/**
 * __usePropertyAppraisalQuery__
 *
 * To run a query within a React component, call `usePropertyAppraisalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyAppraisalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyAppraisalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyAppraisalQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PropertyAppraisalQuery, PropertyAppraisalQueryVariables>) {
        return ApolloReactHooks.useQuery<PropertyAppraisalQuery, PropertyAppraisalQueryVariables>(PropertyAppraisalDocument, baseOptions);
      }
export function usePropertyAppraisalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PropertyAppraisalQuery, PropertyAppraisalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PropertyAppraisalQuery, PropertyAppraisalQueryVariables>(PropertyAppraisalDocument, baseOptions);
        }
export type PropertyAppraisalQueryHookResult = ReturnType<typeof usePropertyAppraisalQuery>;
export type PropertyAppraisalLazyQueryHookResult = ReturnType<typeof usePropertyAppraisalLazyQuery>;
export type PropertyAppraisalQueryResult = ApolloReactCommon.QueryResult<PropertyAppraisalQuery, PropertyAppraisalQueryVariables>;
export const PropertyAppraisalRequestDocument = gql`
    query propertyAppraisalRequest($id: ID!) {
  propertyAppraisalRequest(id: $id) {
    ...PropertyAppraisalRequestBase
  }
}
    ${PropertyAppraisalRequestBaseFragmentDoc}`;

/**
 * __usePropertyAppraisalRequestQuery__
 *
 * To run a query within a React component, call `usePropertyAppraisalRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyAppraisalRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyAppraisalRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyAppraisalRequestQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PropertyAppraisalRequestQuery, PropertyAppraisalRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<PropertyAppraisalRequestQuery, PropertyAppraisalRequestQueryVariables>(PropertyAppraisalRequestDocument, baseOptions);
      }
export function usePropertyAppraisalRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PropertyAppraisalRequestQuery, PropertyAppraisalRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PropertyAppraisalRequestQuery, PropertyAppraisalRequestQueryVariables>(PropertyAppraisalRequestDocument, baseOptions);
        }
export type PropertyAppraisalRequestQueryHookResult = ReturnType<typeof usePropertyAppraisalRequestQuery>;
export type PropertyAppraisalRequestLazyQueryHookResult = ReturnType<typeof usePropertyAppraisalRequestLazyQuery>;
export type PropertyAppraisalRequestQueryResult = ApolloReactCommon.QueryResult<PropertyAppraisalRequestQuery, PropertyAppraisalRequestQueryVariables>;
export const PropertyAppraisalRequestSeenByAgentDocument = gql`
    mutation propertyAppraisalRequestSeenByAgent($id: ID!) {
  propertyAppraisalRequestSeenByAgent(id: $id) {
    ...PropertyAppraisalRequestBase
  }
}
    ${PropertyAppraisalRequestBaseFragmentDoc}`;
export type PropertyAppraisalRequestSeenByAgentMutationFn = ApolloReactCommon.MutationFunction<PropertyAppraisalRequestSeenByAgentMutation, PropertyAppraisalRequestSeenByAgentMutationVariables>;

/**
 * __usePropertyAppraisalRequestSeenByAgentMutation__
 *
 * To run a mutation, you first call `usePropertyAppraisalRequestSeenByAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertyAppraisalRequestSeenByAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertyAppraisalRequestSeenByAgentMutation, { data, loading, error }] = usePropertyAppraisalRequestSeenByAgentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePropertyAppraisalRequestSeenByAgentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PropertyAppraisalRequestSeenByAgentMutation, PropertyAppraisalRequestSeenByAgentMutationVariables>) {
        return ApolloReactHooks.useMutation<PropertyAppraisalRequestSeenByAgentMutation, PropertyAppraisalRequestSeenByAgentMutationVariables>(PropertyAppraisalRequestSeenByAgentDocument, baseOptions);
      }
export type PropertyAppraisalRequestSeenByAgentMutationHookResult = ReturnType<typeof usePropertyAppraisalRequestSeenByAgentMutation>;
export type PropertyAppraisalRequestSeenByAgentMutationResult = ApolloReactCommon.MutationResult<PropertyAppraisalRequestSeenByAgentMutation>;
export type PropertyAppraisalRequestSeenByAgentMutationOptions = ApolloReactCommon.BaseMutationOptions<PropertyAppraisalRequestSeenByAgentMutation, PropertyAppraisalRequestSeenByAgentMutationVariables>;
export const PropertyCompletedWithoutAgentSpotDocument = gql`
    mutation propertyCompletedWithoutAgentSpot($propertyId: ID!, $completeType: PropertyCompleteTypeWithoutAgentspot!) {
  propertyCompletedWithoutAgentSpot(
    propertyId: $propertyId
    completeType: $completeType
  ) {
    ...PropertyBase
  }
}
    ${PropertyBaseFragmentDoc}`;
export type PropertyCompletedWithoutAgentSpotMutationFn = ApolloReactCommon.MutationFunction<PropertyCompletedWithoutAgentSpotMutation, PropertyCompletedWithoutAgentSpotMutationVariables>;

/**
 * __usePropertyCompletedWithoutAgentSpotMutation__
 *
 * To run a mutation, you first call `usePropertyCompletedWithoutAgentSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertyCompletedWithoutAgentSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertyCompletedWithoutAgentSpotMutation, { data, loading, error }] = usePropertyCompletedWithoutAgentSpotMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      completeType: // value for 'completeType'
 *   },
 * });
 */
export function usePropertyCompletedWithoutAgentSpotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PropertyCompletedWithoutAgentSpotMutation, PropertyCompletedWithoutAgentSpotMutationVariables>) {
        return ApolloReactHooks.useMutation<PropertyCompletedWithoutAgentSpotMutation, PropertyCompletedWithoutAgentSpotMutationVariables>(PropertyCompletedWithoutAgentSpotDocument, baseOptions);
      }
export type PropertyCompletedWithoutAgentSpotMutationHookResult = ReturnType<typeof usePropertyCompletedWithoutAgentSpotMutation>;
export type PropertyCompletedWithoutAgentSpotMutationResult = ApolloReactCommon.MutationResult<PropertyCompletedWithoutAgentSpotMutation>;
export type PropertyCompletedWithoutAgentSpotMutationOptions = ApolloReactCommon.BaseMutationOptions<PropertyCompletedWithoutAgentSpotMutation, PropertyCompletedWithoutAgentSpotMutationVariables>;
export const PropertySoldThroughAgentSpotDocument = gql`
    mutation propertySoldThroughAgentSpot($propertyId: ID!, $sellingAgentId: ID!, $accountNumber: String!, $bsb: String!) {
  propertySoldThroughAgentSpot(
    propertyId: $propertyId
    sellingAgentId: $sellingAgentId
    accountNumber: $accountNumber
    bsb: $bsb
  ) {
    ...PropertyBase
  }
}
    ${PropertyBaseFragmentDoc}`;
export type PropertySoldThroughAgentSpotMutationFn = ApolloReactCommon.MutationFunction<PropertySoldThroughAgentSpotMutation, PropertySoldThroughAgentSpotMutationVariables>;

/**
 * __usePropertySoldThroughAgentSpotMutation__
 *
 * To run a mutation, you first call `usePropertySoldThroughAgentSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertySoldThroughAgentSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertySoldThroughAgentSpotMutation, { data, loading, error }] = usePropertySoldThroughAgentSpotMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      sellingAgentId: // value for 'sellingAgentId'
 *      accountNumber: // value for 'accountNumber'
 *      bsb: // value for 'bsb'
 *   },
 * });
 */
export function usePropertySoldThroughAgentSpotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PropertySoldThroughAgentSpotMutation, PropertySoldThroughAgentSpotMutationVariables>) {
        return ApolloReactHooks.useMutation<PropertySoldThroughAgentSpotMutation, PropertySoldThroughAgentSpotMutationVariables>(PropertySoldThroughAgentSpotDocument, baseOptions);
      }
export type PropertySoldThroughAgentSpotMutationHookResult = ReturnType<typeof usePropertySoldThroughAgentSpotMutation>;
export type PropertySoldThroughAgentSpotMutationResult = ApolloReactCommon.MutationResult<PropertySoldThroughAgentSpotMutation>;
export type PropertySoldThroughAgentSpotMutationOptions = ApolloReactCommon.BaseMutationOptions<PropertySoldThroughAgentSpotMutation, PropertySoldThroughAgentSpotMutationVariables>;
export const RequestAppointmentForAgentFromPropertyAppraisalDocument = gql`
    mutation requestAppointmentForAgentFromPropertyAppraisal($appointmentDate: Date!, $appointmentTime: Time!, $propertyAppraisalId: ID!) {
  requestAppointmentForAgentFromPropertyAppraisal(
    propertyAppraisalId: $propertyAppraisalId
    appointmentTime: $appointmentTime
    appointmentDate: $appointmentDate
  ) {
    ...PropertyAppraisalBase
  }
}
    ${PropertyAppraisalBaseFragmentDoc}`;
export type RequestAppointmentForAgentFromPropertyAppraisalMutationFn = ApolloReactCommon.MutationFunction<RequestAppointmentForAgentFromPropertyAppraisalMutation, RequestAppointmentForAgentFromPropertyAppraisalMutationVariables>;

/**
 * __useRequestAppointmentForAgentFromPropertyAppraisalMutation__
 *
 * To run a mutation, you first call `useRequestAppointmentForAgentFromPropertyAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAppointmentForAgentFromPropertyAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAppointmentForAgentFromPropertyAppraisalMutation, { data, loading, error }] = useRequestAppointmentForAgentFromPropertyAppraisalMutation({
 *   variables: {
 *      appointmentDate: // value for 'appointmentDate'
 *      appointmentTime: // value for 'appointmentTime'
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *   },
 * });
 */
export function useRequestAppointmentForAgentFromPropertyAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestAppointmentForAgentFromPropertyAppraisalMutation, RequestAppointmentForAgentFromPropertyAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestAppointmentForAgentFromPropertyAppraisalMutation, RequestAppointmentForAgentFromPropertyAppraisalMutationVariables>(RequestAppointmentForAgentFromPropertyAppraisalDocument, baseOptions);
      }
export type RequestAppointmentForAgentFromPropertyAppraisalMutationHookResult = ReturnType<typeof useRequestAppointmentForAgentFromPropertyAppraisalMutation>;
export type RequestAppointmentForAgentFromPropertyAppraisalMutationResult = ApolloReactCommon.MutationResult<RequestAppointmentForAgentFromPropertyAppraisalMutation>;
export type RequestAppointmentForAgentFromPropertyAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestAppointmentForAgentFromPropertyAppraisalMutation, RequestAppointmentForAgentFromPropertyAppraisalMutationVariables>;
export const StartNewDraftPropertyAppraisalDocument = gql`
    mutation startNewDraftPropertyAppraisal($propertyAppraisalRequestId: ID!) {
  startNewDraftPropertyAppraisal(
    propertyAppraisalRequestId: $propertyAppraisalRequestId
  ) {
    ...PropertyAppraisalRequestBase
  }
}
    ${PropertyAppraisalRequestBaseFragmentDoc}`;
export type StartNewDraftPropertyAppraisalMutationFn = ApolloReactCommon.MutationFunction<StartNewDraftPropertyAppraisalMutation, StartNewDraftPropertyAppraisalMutationVariables>;

/**
 * __useStartNewDraftPropertyAppraisalMutation__
 *
 * To run a mutation, you first call `useStartNewDraftPropertyAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartNewDraftPropertyAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startNewDraftPropertyAppraisalMutation, { data, loading, error }] = useStartNewDraftPropertyAppraisalMutation({
 *   variables: {
 *      propertyAppraisalRequestId: // value for 'propertyAppraisalRequestId'
 *   },
 * });
 */
export function useStartNewDraftPropertyAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartNewDraftPropertyAppraisalMutation, StartNewDraftPropertyAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<StartNewDraftPropertyAppraisalMutation, StartNewDraftPropertyAppraisalMutationVariables>(StartNewDraftPropertyAppraisalDocument, baseOptions);
      }
export type StartNewDraftPropertyAppraisalMutationHookResult = ReturnType<typeof useStartNewDraftPropertyAppraisalMutation>;
export type StartNewDraftPropertyAppraisalMutationResult = ApolloReactCommon.MutationResult<StartNewDraftPropertyAppraisalMutation>;
export type StartNewDraftPropertyAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<StartNewDraftPropertyAppraisalMutation, StartNewDraftPropertyAppraisalMutationVariables>;
export const SubmitPropertyAppraisalDocument = gql`
    mutation submitPropertyAppraisal($propertyAppraisalId: ID!) {
  submitPropertyAppraisal(propertyAppraisalId: $propertyAppraisalId) {
    ...PropertyAppraisalRequestBase
  }
}
    ${PropertyAppraisalRequestBaseFragmentDoc}`;
export type SubmitPropertyAppraisalMutationFn = ApolloReactCommon.MutationFunction<SubmitPropertyAppraisalMutation, SubmitPropertyAppraisalMutationVariables>;

/**
 * __useSubmitPropertyAppraisalMutation__
 *
 * To run a mutation, you first call `useSubmitPropertyAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPropertyAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPropertyAppraisalMutation, { data, loading, error }] = useSubmitPropertyAppraisalMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *   },
 * });
 */
export function useSubmitPropertyAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitPropertyAppraisalMutation, SubmitPropertyAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitPropertyAppraisalMutation, SubmitPropertyAppraisalMutationVariables>(SubmitPropertyAppraisalDocument, baseOptions);
      }
export type SubmitPropertyAppraisalMutationHookResult = ReturnType<typeof useSubmitPropertyAppraisalMutation>;
export type SubmitPropertyAppraisalMutationResult = ApolloReactCommon.MutationResult<SubmitPropertyAppraisalMutation>;
export type SubmitPropertyAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitPropertyAppraisalMutation, SubmitPropertyAppraisalMutationVariables>;
export const UpdateDraftPropertyAppraisalAdditionalNotesDocument = gql`
    mutation updateDraftPropertyAppraisalAdditionalNotes($propertyAppraisalId: ID!, $additionalNotes: String!) {
  updateDraftPropertyAppraisalAdditionalNotes(
    propertyAppraisalId: $propertyAppraisalId
    additionalNotes: $additionalNotes
  ) {
    ...DraftPropertyAppraisalBase
  }
}
    ${DraftPropertyAppraisalBaseFragmentDoc}`;
export type UpdateDraftPropertyAppraisalAdditionalNotesMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftPropertyAppraisalAdditionalNotesMutation, UpdateDraftPropertyAppraisalAdditionalNotesMutationVariables>;

/**
 * __useUpdateDraftPropertyAppraisalAdditionalNotesMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertyAppraisalAdditionalNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertyAppraisalAdditionalNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertyAppraisalAdditionalNotesMutation, { data, loading, error }] = useUpdateDraftPropertyAppraisalAdditionalNotesMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *      additionalNotes: // value for 'additionalNotes'
 *   },
 * });
 */
export function useUpdateDraftPropertyAppraisalAdditionalNotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftPropertyAppraisalAdditionalNotesMutation, UpdateDraftPropertyAppraisalAdditionalNotesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftPropertyAppraisalAdditionalNotesMutation, UpdateDraftPropertyAppraisalAdditionalNotesMutationVariables>(UpdateDraftPropertyAppraisalAdditionalNotesDocument, baseOptions);
      }
export type UpdateDraftPropertyAppraisalAdditionalNotesMutationHookResult = ReturnType<typeof useUpdateDraftPropertyAppraisalAdditionalNotesMutation>;
export type UpdateDraftPropertyAppraisalAdditionalNotesMutationResult = ApolloReactCommon.MutationResult<UpdateDraftPropertyAppraisalAdditionalNotesMutation>;
export type UpdateDraftPropertyAppraisalAdditionalNotesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftPropertyAppraisalAdditionalNotesMutation, UpdateDraftPropertyAppraisalAdditionalNotesMutationVariables>;
export const UpdateDraftPropertyAppraisalCommissionDocument = gql`
    mutation updateDraftPropertyAppraisalCommission($propertyAppraisalId: ID!, $commissionType: AgentCommissionCommissionType!, $percentageRate: Float, $flatFeeCostDollars: Float) {
  updateDraftPropertyAppraisalCommission(
    propertyAppraisalId: $propertyAppraisalId
    commissionType: $commissionType
    percentageRate: $percentageRate
    flatFeeCostDollars: $flatFeeCostDollars
  ) {
    ...DraftPropertyAppraisalBase
  }
}
    ${DraftPropertyAppraisalBaseFragmentDoc}`;
export type UpdateDraftPropertyAppraisalCommissionMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftPropertyAppraisalCommissionMutation, UpdateDraftPropertyAppraisalCommissionMutationVariables>;

/**
 * __useUpdateDraftPropertyAppraisalCommissionMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertyAppraisalCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertyAppraisalCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertyAppraisalCommissionMutation, { data, loading, error }] = useUpdateDraftPropertyAppraisalCommissionMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *      commissionType: // value for 'commissionType'
 *      percentageRate: // value for 'percentageRate'
 *      flatFeeCostDollars: // value for 'flatFeeCostDollars'
 *   },
 * });
 */
export function useUpdateDraftPropertyAppraisalCommissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftPropertyAppraisalCommissionMutation, UpdateDraftPropertyAppraisalCommissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftPropertyAppraisalCommissionMutation, UpdateDraftPropertyAppraisalCommissionMutationVariables>(UpdateDraftPropertyAppraisalCommissionDocument, baseOptions);
      }
export type UpdateDraftPropertyAppraisalCommissionMutationHookResult = ReturnType<typeof useUpdateDraftPropertyAppraisalCommissionMutation>;
export type UpdateDraftPropertyAppraisalCommissionMutationResult = ApolloReactCommon.MutationResult<UpdateDraftPropertyAppraisalCommissionMutation>;
export type UpdateDraftPropertyAppraisalCommissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftPropertyAppraisalCommissionMutation, UpdateDraftPropertyAppraisalCommissionMutationVariables>;
export const UpdateDraftPropertyAppraisalComparableSalesDocument = gql`
    mutation updateDraftPropertyAppraisalComparableSales($propertyAppraisalId: ID!, $comparableSales: [PropertyAppraisalComparableSaleInput!]!) {
  updateDraftPropertyAppraisalComparableSales(
    propertyAppraisalId: $propertyAppraisalId
    comparableSales: $comparableSales
  ) {
    ...DraftPropertyAppraisalBase
  }
}
    ${DraftPropertyAppraisalBaseFragmentDoc}`;
export type UpdateDraftPropertyAppraisalComparableSalesMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftPropertyAppraisalComparableSalesMutation, UpdateDraftPropertyAppraisalComparableSalesMutationVariables>;

/**
 * __useUpdateDraftPropertyAppraisalComparableSalesMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertyAppraisalComparableSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertyAppraisalComparableSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertyAppraisalComparableSalesMutation, { data, loading, error }] = useUpdateDraftPropertyAppraisalComparableSalesMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *      comparableSales: // value for 'comparableSales'
 *   },
 * });
 */
export function useUpdateDraftPropertyAppraisalComparableSalesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftPropertyAppraisalComparableSalesMutation, UpdateDraftPropertyAppraisalComparableSalesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftPropertyAppraisalComparableSalesMutation, UpdateDraftPropertyAppraisalComparableSalesMutationVariables>(UpdateDraftPropertyAppraisalComparableSalesDocument, baseOptions);
      }
export type UpdateDraftPropertyAppraisalComparableSalesMutationHookResult = ReturnType<typeof useUpdateDraftPropertyAppraisalComparableSalesMutation>;
export type UpdateDraftPropertyAppraisalComparableSalesMutationResult = ApolloReactCommon.MutationResult<UpdateDraftPropertyAppraisalComparableSalesMutation>;
export type UpdateDraftPropertyAppraisalComparableSalesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftPropertyAppraisalComparableSalesMutation, UpdateDraftPropertyAppraisalComparableSalesMutationVariables>;
export const UpdateDraftPropertyAppraisalMarketingFeeDocument = gql`
    mutation updateDraftPropertyAppraisalMarketingFee($propertyAppraisalId: ID!, $costDollars: Float!, $marketingInclusions: [String!]!) {
  updateDraftPropertyAppraisalMarketingFee(
    propertyAppraisalId: $propertyAppraisalId
    costDollars: $costDollars
    marketingInclusions: $marketingInclusions
  ) {
    ...DraftPropertyAppraisalBase
  }
}
    ${DraftPropertyAppraisalBaseFragmentDoc}`;
export type UpdateDraftPropertyAppraisalMarketingFeeMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftPropertyAppraisalMarketingFeeMutation, UpdateDraftPropertyAppraisalMarketingFeeMutationVariables>;

/**
 * __useUpdateDraftPropertyAppraisalMarketingFeeMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertyAppraisalMarketingFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertyAppraisalMarketingFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertyAppraisalMarketingFeeMutation, { data, loading, error }] = useUpdateDraftPropertyAppraisalMarketingFeeMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *      costDollars: // value for 'costDollars'
 *      marketingInclusions: // value for 'marketingInclusions'
 *   },
 * });
 */
export function useUpdateDraftPropertyAppraisalMarketingFeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftPropertyAppraisalMarketingFeeMutation, UpdateDraftPropertyAppraisalMarketingFeeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftPropertyAppraisalMarketingFeeMutation, UpdateDraftPropertyAppraisalMarketingFeeMutationVariables>(UpdateDraftPropertyAppraisalMarketingFeeDocument, baseOptions);
      }
export type UpdateDraftPropertyAppraisalMarketingFeeMutationHookResult = ReturnType<typeof useUpdateDraftPropertyAppraisalMarketingFeeMutation>;
export type UpdateDraftPropertyAppraisalMarketingFeeMutationResult = ApolloReactCommon.MutationResult<UpdateDraftPropertyAppraisalMarketingFeeMutation>;
export type UpdateDraftPropertyAppraisalMarketingFeeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftPropertyAppraisalMarketingFeeMutation, UpdateDraftPropertyAppraisalMarketingFeeMutationVariables>;
export const UpdateDraftPropertyAppraisalSellingRangeDocument = gql`
    mutation updateDraftPropertyAppraisalSellingRange($propertyAppraisalId: ID!, $sellingRangeMin: Int!, $sellingRangeMax: Int!) {
  updateDraftPropertyAppraisalSellingRange(
    propertyAppraisalId: $propertyAppraisalId
    sellingRangeMin: $sellingRangeMin
    sellingRangeMax: $sellingRangeMax
  ) {
    ...DraftPropertyAppraisalBase
  }
}
    ${DraftPropertyAppraisalBaseFragmentDoc}`;
export type UpdateDraftPropertyAppraisalSellingRangeMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftPropertyAppraisalSellingRangeMutation, UpdateDraftPropertyAppraisalSellingRangeMutationVariables>;

/**
 * __useUpdateDraftPropertyAppraisalSellingRangeMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertyAppraisalSellingRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertyAppraisalSellingRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertyAppraisalSellingRangeMutation, { data, loading, error }] = useUpdateDraftPropertyAppraisalSellingRangeMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *      sellingRangeMin: // value for 'sellingRangeMin'
 *      sellingRangeMax: // value for 'sellingRangeMax'
 *   },
 * });
 */
export function useUpdateDraftPropertyAppraisalSellingRangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftPropertyAppraisalSellingRangeMutation, UpdateDraftPropertyAppraisalSellingRangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftPropertyAppraisalSellingRangeMutation, UpdateDraftPropertyAppraisalSellingRangeMutationVariables>(UpdateDraftPropertyAppraisalSellingRangeDocument, baseOptions);
      }
export type UpdateDraftPropertyAppraisalSellingRangeMutationHookResult = ReturnType<typeof useUpdateDraftPropertyAppraisalSellingRangeMutation>;
export type UpdateDraftPropertyAppraisalSellingRangeMutationResult = ApolloReactCommon.MutationResult<UpdateDraftPropertyAppraisalSellingRangeMutation>;
export type UpdateDraftPropertyAppraisalSellingRangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftPropertyAppraisalSellingRangeMutation, UpdateDraftPropertyAppraisalSellingRangeMutationVariables>;
export const UpdateDraftPropertyAppraisalVideoDocument = gql`
    mutation updateDraftPropertyAppraisalVideo($propertyAppraisalId: ID!, $videoUserMediaId: ID) {
  updateDraftPropertyAppraisalVideo(
    propertyAppraisalId: $propertyAppraisalId
    videoUserMediaId: $videoUserMediaId
  ) {
    ...DraftPropertyAppraisalBase
  }
}
    ${DraftPropertyAppraisalBaseFragmentDoc}`;
export type UpdateDraftPropertyAppraisalVideoMutationFn = ApolloReactCommon.MutationFunction<UpdateDraftPropertyAppraisalVideoMutation, UpdateDraftPropertyAppraisalVideoMutationVariables>;

/**
 * __useUpdateDraftPropertyAppraisalVideoMutation__
 *
 * To run a mutation, you first call `useUpdateDraftPropertyAppraisalVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftPropertyAppraisalVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftPropertyAppraisalVideoMutation, { data, loading, error }] = useUpdateDraftPropertyAppraisalVideoMutation({
 *   variables: {
 *      propertyAppraisalId: // value for 'propertyAppraisalId'
 *      videoUserMediaId: // value for 'videoUserMediaId'
 *   },
 * });
 */
export function useUpdateDraftPropertyAppraisalVideoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDraftPropertyAppraisalVideoMutation, UpdateDraftPropertyAppraisalVideoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDraftPropertyAppraisalVideoMutation, UpdateDraftPropertyAppraisalVideoMutationVariables>(UpdateDraftPropertyAppraisalVideoDocument, baseOptions);
      }
export type UpdateDraftPropertyAppraisalVideoMutationHookResult = ReturnType<typeof useUpdateDraftPropertyAppraisalVideoMutation>;
export type UpdateDraftPropertyAppraisalVideoMutationResult = ApolloReactCommon.MutationResult<UpdateDraftPropertyAppraisalVideoMutation>;
export type UpdateDraftPropertyAppraisalVideoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDraftPropertyAppraisalVideoMutation, UpdateDraftPropertyAppraisalVideoMutationVariables>;
export const StaticCashbackAmountDocument = gql`
    query staticCashbackAmount {
  staticCashbackAmount
}
    `;

/**
 * __useStaticCashbackAmountQuery__
 *
 * To run a query within a React component, call `useStaticCashbackAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticCashbackAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticCashbackAmountQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaticCashbackAmountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaticCashbackAmountQuery, StaticCashbackAmountQueryVariables>) {
        return ApolloReactHooks.useQuery<StaticCashbackAmountQuery, StaticCashbackAmountQueryVariables>(StaticCashbackAmountDocument, baseOptions);
      }
export function useStaticCashbackAmountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaticCashbackAmountQuery, StaticCashbackAmountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaticCashbackAmountQuery, StaticCashbackAmountQueryVariables>(StaticCashbackAmountDocument, baseOptions);
        }
export type StaticCashbackAmountQueryHookResult = ReturnType<typeof useStaticCashbackAmountQuery>;
export type StaticCashbackAmountLazyQueryHookResult = ReturnType<typeof useStaticCashbackAmountLazyQuery>;
export type StaticCashbackAmountQueryResult = ApolloReactCommon.QueryResult<StaticCashbackAmountQuery, StaticCashbackAmountQueryVariables>;