import React, { FC, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import TextTruncate from 'react-text-truncate';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { ReactComponent as AgentSpotLogoIcon } from '../../../assets/agentspot-print-logo.svg';
import AgentProfilePhoto from '../../../components/AgentProfilePhoto';
import ASActionButton from '../../../components/ASActionButton';
import ASModal from '../../../components/ASModal';
import InnerNavigationHeader from '../../../components/InnerNavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import SellerMenu from '../../../components/SellerMenu';
import SplitDashboardPageTemplate from '../../../components/SplitDashboardPageTemplate';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import theme from '../../../constants/theme';
import {
  PropertyAppraisalBaseFragment,
  PropertyCompleteType,
  PropertyState,
  usePropertyAppraisalQuery,
} from '../../../graphql/generated';
import useIsDesktop from '../../../hooks/useIsDekstop';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import printerIconUrl, {
  ReactComponent as PrinterIcon,
} from '../assets/printer.svg';
import AgentBadgeRow, {
  usePrecacheAgentBadges,
} from '../components/AgentBadgeRow';
import AppraisalAdditionalNotes from '../components/AppraisalAdditionalNotes';
import AppraisalComparableSales, {
  usePrecacheForComparableSales,
} from '../components/AppraisalComparableSales';
import AppraisalFeesBox, {
  usePrecacheForFeesBox,
} from '../components/AppraisalFeesBox';
import AppraisalPriceRangeBox, {
  usePrecacheForSellPriceBox,
} from '../components/AppraisalPriceRangeBox';
import CreateListingButton from '../components/CreateListingButton';
import UniversalPropertyActionMenu from '../components/UniversalPropertyActionMenu';
import AppraisalVideo, { usePrecacheForAppraisalVideo } from './AppraisalVideo';
import './PrintAppraisal.css';
import { usePrecacheForRequestAppointment } from './RequestAppointment';

const AgentName = styled.h1`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-size: ${pxToRem(27)};
  color: #203553;
  margin: 0;
  font-weight: 700;
`;

const Agency = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: ${pxToRem(14)};
  font-weight: 500;
  color: #8f9aa9;
  letter-spacing: 0.17px;
  margin-top: 8px;
`;

const PropertiesListed = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(12)};
  color: #a6aeba;
  letter-spacing: 0.15px;
  margin: 20px 0;
`;

const Address = styled.h3`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: ${pxToRem(18)};
  color: #203553;
  letter-spacing: 0.19px;
  text-align: center;
  line-height: 26px;
  margin-top: 18px;
  margin-bottom: 5px;
`;

const SuburbState = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: ${pxToRem(12)};
  color: #8f9aa9;
  letter-spacing: 0.15px;
  align-self: center;
  margin-bottom: 30px;
  text-align: center;
`;

const Appraisal = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: ${pxToRem(15)};
  color: #8f9aa9;
  letter-spacing: 0.16px;
  text-align: center;
  line-height: 20px;
  padding: 25px 0 0 0;
  margin: 0;
  border-top: 1px solid #e8eaed;
`;

const AgencyBioLink = styled.button`
  font-family: Inter, Arial, sans-serif;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  color: #1745b0;
  letter-spacing: 0.2px;

  text-align: left;
  border: none;
  padding: 0;
  background-color: #ffffff;

  :hover {
    text-decoration-line: underline;
    text-decoration-color: #ffffff;
  }

  @media print {
    display: none;
  }
`;

const AgencyBio = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: ${pxToRem(13)};
  color: #8f9aa9;
  letter-spacing: 0.14px;
  line-height: 22px;
`;

const AgentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AgentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid #e8eaed;
  margin: 23px 0 25px 0;
  padding-bottom: 20px;

  @media print {
    page-break-inside: avoid;
  }
`;
const RequestAppointmentButton = styled(ASActionButton)`
  margin-top: 15px;
  width: 100%;
`;

const AppraisalDetailsContainer = styled.div`
  margin: 0 ${theme.spacing.pageInset};

  @media print {
    margin: 0 10mm;
  }
`;

const AgentBioContainer = styled.div`
  margin-bottom: 20px;

  @media print {
    page-break-inside: avoid;
    margin-bottom: 40px;
  }
`;

const BioToggleButton = styled.button`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(14)};
  color: #1745b0;
  letter-spacing: 0.2px;
  margin: 0;
  padding: 19px 0;

  text-align: left;
  border: none;
  background-color: #ffffff;

  :hover {
    text-decoration-line: underline;
    text-decoration-color: #ffffff;
  }

  @media print {
    display: none;
  }
`;

const DisclaimerHeader = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #203553;
  letter-spacing: 0.19px;
  line-height: 23px;
  margin-top: 50px;
`;

const Disclaimer = styled.div`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #57667b;
  letter-spacing: 0.19px;
  line-height: 23px;
  margin-bottom: 150px;

  @media print {
    page-break-inside: avoid;
  }
`;

const NavigationButtonsContainer = styled.div`
  display: flex;
`;

const PrintButton = styled.button`
  margin: 0 16px 0 0;
  background: transparent;
  border: none;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    display: none;
  }
`;

const AgentSpotLogo = styled(AgentSpotLogoIcon)`
  @media screen {
    display: none;
  }
  @media print {
    display: block;
    margin-left: 5mm;
  }
`;

const PrintHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10mm 52px 10mm;

  @media screen {
    display: none;
  }
`;

const PrintHeaderTitle = styled.p`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 800;
  font-size: ${pxToRem(18)};
  color: #07134f;
`;

const PrintAddress = styled.p`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 800;
  font-size: ${pxToRem(24)};
  color: #07134f;
  margin: 0 10mm;

  @media screen {
    display: none;
  }
`;

const PrintSeparator = styled.div`
  background: #07134f;
  height: 6px;
  width: 100vw;
  margin-bottom: 50px;

  @media screen {
    display: none;
  }
`;

const PrintSuburb = styled.p`
  font-family: Inter, Arial, sans-serif;
  color: #58687e;
  letter-spacing: 0.16px;
  font-weight: 500;
  font-size: ${pxToRem(13)};
  margin: 10px 10mm 33px 10mm;

  @media screen {
    display: none;
  }
`;

const AddressContainer = styled.div`
  @media print {
    display: none;
  }
`;

const PrintProfilePhoto = styled.img`
  display: flex;
  border-radius: 50%;
  background-color: #f4f5f6;
  border: 1px solid #e8eaed;
  position: center;
  object-fit: cover;
  flex: 1;
  aspect-ratio: 0.5;
  width: 75px;
  height: 75px;
  max-width: 75px;
  max-height: 75px;
  margin-right: 6px;

  @media screen {
    display: none;
  }
`;

const AgentPhotoContainer = styled.div`
  @media print {
    display: none;
  }
`;

interface ExpectedPathParams {
  id?: string;
  appraisalId?: string;
}

export const usePrecacheForPropertyAppraisalDetails: () => void = () => {
  usePrecacheImages([printerIconUrl]);
  usePrecacheAgentBadges();
  usePrecacheForComparableSales();
  usePrecacheForFeesBox();
  usePrecacheForSellPriceBox();
  usePrecacheForAppraisalVideo();
};
export interface PropertyAppraisalDetailsProps {
  appraisal: PropertyAppraisalBaseFragment;
}

const PropertyAppraisalDetails: FC<PropertyAppraisalDetailsProps> = () => {
  const { appraisalId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  /* eslint-disable-next-line */
  // @ts-ignore
  // eslint-disable-next-line
  const printRef = useRef<any>();

  const [agencyBioModalOpen, setAgencyBioModalOpen] = useState<boolean>(false);
  const [agentBioTruncated, setAgentBioTruncated] = useState<boolean>(true);

  const { data: appraisalData, error } = usePropertyAppraisalQuery({
    variables: {
      id: typeof appraisalId === 'string' ? appraisalId : '',
    },
  });

  const isDesktop = useIsDesktop();

  const onRequestAppointment = () => {
    history.push(
      `/property/${appraisalData?.propertyAppraisal.property.id}/appraisal/${appraisalData?.propertyAppraisal.id}/request-appointment`,
    );
  };

  usePrecacheForRequestAppointment();

  return (
    <SplitDashboardPageTemplate
      menu={<SellerMenu />}
      navigationHeaderContent={<CreateListingButton />}
      noHorzintalPadding
      footer={
        !appraisalData?.propertyAppraisal.appointmentRequestedBySeller ? (
          // <FixedFooter $sticky>
          <RequestAppointmentButton
            disabled={false}
            loading={false}
            onClick={onRequestAppointment}>
            Request Appointment
          </RequestAppointmentButton>
        ) : // </FixedFooter>
        undefined
      }
      padFooter>
      <Helmet>
        <title>Appraisal Details | AgentSpot</title>
      </Helmet>
      {!appraisalData && error && error.message === ErrorCodes.NotFound && (
        <NotFoundPlaceholder />
      )}

      {appraisalData && (
        <>
          <InnerNavigationHeader
            backHref={`/property/${appraisalData.propertyAppraisal.property.id}`}
            pageTitle="Property Appraisal"
            rightSideComponent={
              !(
                appraisalData?.propertyAppraisal.property?.state ===
                  PropertyState.Complete &&
                appraisalData?.propertyAppraisal.property?.completeType ===
                  PropertyCompleteType.SoldUnconditionally
              ) && (
                <NavigationButtonsContainer>
                  <ReactToPrint
                    onBeforeGetContent={async () => setAgentBioTruncated(false)}
                    trigger={() => (
                      <PrintButton>
                        <PrinterIcon />
                      </PrintButton>
                    )}
                    content={() => printRef.current ?? null}
                  />
                  <UniversalPropertyActionMenu
                    property={appraisalData?.propertyAppraisal.property}
                  />
                </NavigationButtonsContainer>
              )
            }
          />
          {appraisalData.propertyAppraisal.appraisalVideo && (
            <AppraisalVideo
              videoThumbnail={
                appraisalData.propertyAppraisal.appraisalVideo?.thumbnailUrl ??
                ''
              }
              videoUrl={
                appraisalData.propertyAppraisal.appraisalVideo?.url ?? ''
              }
            />
          )}
          <div ref={printRef}>
            <PrintHeader>
              <PrintHeaderTitle>Property Appraisal</PrintHeaderTitle>
              <AgentSpotLogo />
            </PrintHeader>
            <PrintAddress>
              {appraisalData.propertyAppraisal.property.streetName}
            </PrintAddress>
            <PrintSuburb>
              {`${appraisalData.propertyAppraisal.property.suburb.name}, ${appraisalData.propertyAppraisal.property.suburb.state}`}
            </PrintSuburb>
            <PrintSeparator />
            <AppraisalDetailsContainer>
              <AgentContainer>
                <AgentDetailsContainer>
                  <AgentName>
                    {appraisalData?.propertyAppraisal?.agent?.name}
                  </AgentName>
                  <Agency>
                    {appraisalData?.propertyAppraisal?.agent.agencyName}
                  </Agency>
                  <AgencyBioLink onClick={() => setAgencyBioModalOpen(true)}>
                    View Agency Bio
                  </AgencyBioLink>
                </AgentDetailsContainer>
                <AgentPhotoContainer>
                  <AgentProfilePhoto
                    showAgencyLogo
                    agent={appraisalData?.propertyAppraisal.agent}
                    position="top-right"
                  />
                </AgentPhotoContainer>
                <PrintProfilePhoto
                  src={
                    appraisalData?.propertyAppraisal.agent.profilePicture
                      ?.thumbnailUrl
                  }
                />
                <PrintProfilePhoto
                  src={
                    appraisalData?.propertyAppraisal.agent.agencyLogo
                      ?.thumbnailUrl
                  }
                />
              </AgentContainer>
              {appraisalData.propertyAppraisal.agent.badge && (
                <>
                  <AgentBadgeRow
                    badge={
                      appraisalData.propertyAppraisal.agent.badge ?? undefined
                    }
                  />
                  <PropertiesListed>
                    {appraisalData.propertyAppraisal.agent.numberOfEngagements}{' '}
                    properies listed
                  </PropertiesListed>
                </>
              )}
              <AgentBioContainer>
                <TextTruncate
                  // No prop to allow undefined truncated length
                  line={agentBioTruncated ? 1 : 100000}
                  element="div"
                  truncateText="…"
                  text={appraisalData?.propertyAppraisal.agent.bio ?? ''}
                />
                <BioToggleButton
                  onClick={() => setAgentBioTruncated(!agentBioTruncated)}>
                  {agentBioTruncated ? 'Read More' : 'Read Less'}
                </BioToggleButton>
              </AgentBioContainer>
              <AddressContainer>
                <Appraisal>Appraisal</Appraisal>

                <Address>
                  {appraisalData.propertyAppraisal.property.streetName}
                </Address>
                <SuburbState>
                  {`${appraisalData.propertyAppraisal.property.suburb.name}, ${appraisalData.propertyAppraisal.property.suburb.state}`}
                </SuburbState>
              </AddressContainer>
              <AppraisalPriceRangeBox
                sellingRangeMin={
                  appraisalData.propertyAppraisal.sellingRangeMin
                }
                sellingRangeMax={
                  appraisalData.propertyAppraisal.sellingRangeMax
                }
              />
              <AppraisalFeesBox
                commisionRate={
                  appraisalData.propertyAppraisal.commission?.percentageRate ??
                  undefined
                }
                commisionFee={
                  appraisalData.propertyAppraisal.commission
                    ?.flatFeeCostDollars ?? undefined
                }
                commisionType={
                  appraisalData.propertyAppraisal.commission.commissionType
                }
                marketingFee={
                  appraisalData.propertyAppraisal.marketingFee.costDollars
                }
                marketingInclusions={
                  appraisalData.propertyAppraisal.marketingFee
                    .marketingInclusions
                }
              />
              <div className="page-break" />
              {/* TODO: THIS */}
              <AppraisalComparableSales
                comparableSales={
                  appraisalData.propertyAppraisal.comparableSales
                }
              />
              {appraisalData.propertyAppraisal.additionalNotes && (
                <AppraisalAdditionalNotes
                  additionalNotes={
                    appraisalData.propertyAppraisal.additionalNotes
                  }
                />
              )}
              {appraisalData?.propertyAppraisal.property && !isDesktop && (
                <UniversalPropertyActionMenu
                  property={appraisalData?.propertyAppraisal.property}
                />
              )}
              <DisclaimerHeader>Disclaimer</DisclaimerHeader>
              <Disclaimer>
                This appraisal has been prepared solely for the information of
                the client in which it is addressed to and not any other third
                party. Although every case has been taken in performing this
                worth, it must only be taken into consideration as an opinion
                and not a certified valuation. AgentSpot Pty Ltd does not
                guarantee or warrant the accuracy of the information provided by
                any estate agent on our site.
              </Disclaimer>
            </AppraisalDetailsContainer>
          </div>
        </>
      )}

      {appraisalData && (
        <ASModal
          isOpen={agencyBioModalOpen}
          title="Agency History"
          onRequestClose={() => setAgencyBioModalOpen(false)}>
          <AgencyBio>
            {appraisalData?.propertyAppraisal?.agent?.agencyBio}
          </AgencyBio>
        </ASModal>
      )}
    </SplitDashboardPageTemplate>
  );
};

export default PropertyAppraisalDetails;
