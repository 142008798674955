import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import MultiLineTextField from '../../../components/MultiLineTextField';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import {
  useMeQuery,
  useUpdatePropertySaleInformationMutation,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForDetailsComplete } from './DetailsComplete';
import { usePrecacheForSelectAgents } from './SelectAgents';

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  padding: 0 16px 30px 16px;
`;

const ExplanationLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  line-height: 23px;
  max-width: 80%;
  margin-bottom: 17px;
`;

export const usePrecacheForAdditionalInformation: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForSelectAgents();
  usePrecacheForStepTemplate();
};

interface FormValues {
  additionalInformation?: string;
}

const AdditionalInformation: FC = () => {
  const history = useHistory();

  const isAuthenticated = useIsAuthenticated();
  const { data: meData } = useMeQuery({ skip: !isAuthenticated });

  const { draftProperty } = useCurrentDraftProperty();

  const [
    updatePropertySaleInformation,
    { loading: updatePropertySaleInformationLoading },
  ] = useUpdatePropertySaleInformationMutation();

  const [updateUserDetails] = useUpdateUserDetailsMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftProperty) return;

      await updatePropertySaleInformation({
        variables: {
          propertyId: draftProperty.id,
          propertySaleInformation: {
            additionalInformation: values.additionalInformation,
          },
        },
      });

      const nextPage = !meData?.me?.profileComplete
        ? '/new-property/account-password'
        : '/new-property/details-complete';

      updateUserDetails({
        variables: {
          nextPage: nextPage,
        },
      });

      history.push(nextPage);
    },
    [draftProperty, updatePropertySaleInformation, meData],
  );

  const initialValues = !draftProperty
    ? null
    : ({
        additionalInformation: draftProperty.additionalInformation || undefined,
      } as FormValues);

  usePrecacheForDetailsComplete();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Additional Information | AgentSpot</title>
      </Helmet>
      <StepNavigationHeader
        title="Property Details"
        stepCount={6}
        stepIndex={6}
        stepName="Additional information"
      />

      {!draftProperty ? null : (
        <Formik<FormValues> initialValues={initialValues!} onSubmit={onSubmit}>
          {({ submitForm }) => {
            return (
              <>
                <PageContentContainer>
                  <ExplanationLabel>
                    Is there anything you would like to mention to your agent?
                  </ExplanationLabel>
                  <MultiLineTextField
                    fieldName="additionalInformation"
                    placeholder="Optional message"
                    maxLength={3500}
                  />
                </PageContentContainer>
                <StepNavigationFooter
                  backHref="/new-property/photos"
                  onNextClick={submitForm}
                  nextDisabled={updatePropertySaleInformationLoading}
                  loading={updatePropertySaleInformationLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default AdditionalInformation;
