import { FieldArray, Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import AddItemRowSmallButton from '../../../components/AddItemRowSmallButton';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import {
  PropertyAppraisalComparableSaleInput,
  useUpdateDraftPropertyAppraisalComparableSalesMutation,
} from '../../../graphql/generated';
import ComparableSaleInputField, {
  usePrecacheForComparableSaleInputField,
} from '../components/ComparableSaleInputField';
import useDraftPropertyAppraisal from '../hooks/useDraftPropertyAppraisal';
import { usePrecacheForNewAppraisalAdditionalNotes } from './NewAppraisalAdditionalNotes';

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  padding: 0 20px 35px 20px;
`;

export const usePrecacheForNewAppraisalComparableSales: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForStepNavigationFooter();
  usePrecacheForStepTemplate();
  usePrecacheForComparableSaleInputField();
};

interface ExpectedPathParams {
  id: string;
}

interface FormValues {
  comparableSales: PropertyAppraisalComparableSaleInput[];
}

const validationSchema = Yup.object().shape({
  comparableSales: Yup.array(
    Yup.object().shape({
      address: Yup.string().required(),
      salePriceDollars: Yup.number().required().positive(),
      url: Yup.string()
        .trim()
        .matches(
          /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
        )
        .nullable(),
    }),
  ),
});

const NewAppraisalComparableSales: FC = () => {
  const { id: propertyAppraisalRequestId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { draftPropertyAppraisal, notFound } = useDraftPropertyAppraisal(
    propertyAppraisalRequestId,
  );

  const [
    updateDraftPropertyAppraisalComparableSales,
    { loading: updateDraftPropertyAppraisalComparableSalesLoading },
  ] = useUpdateDraftPropertyAppraisalComparableSalesMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftPropertyAppraisal) return;

      await updateDraftPropertyAppraisalComparableSales({
        variables: {
          propertyAppraisalId: draftPropertyAppraisal.id,
          comparableSales: values.comparableSales.map((comparableSale) => ({
            ...comparableSale,
            url: comparableSale.url || null,
          })),
        },
      });

      history.push(
        `/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/video`,
      );
    },
    [draftPropertyAppraisal],
  );

  const initialValues = !draftPropertyAppraisal
    ? null
    : ({
        comparableSales:
          draftPropertyAppraisal.comparableSales.map((comparableSale) => ({
            address: comparableSale.address,
            salePriceDollars: comparableSale.salePriceDollars,
            url: comparableSale.url ?? undefined,
          })) || [],
      } as FormValues);

  usePrecacheForNewAppraisalAdditionalNotes();
  usePrecacheForAgentMenu();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>3 Comparable Sales | AgentSpot</title>
      </Helmet>

      {!draftPropertyAppraisal && notFound && <NotFoundPlaceholder />}
      {!draftPropertyAppraisal ? null : (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount>
          {({ submitForm, values, isValid, setFieldValue }) => {
            return (
              <>
                <PageContentContainer>
                  <StepNavigationHeader
                    title="Appraise property"
                    stepName="3 Comparable Sales"
                    stepIndex={3}
                    stepCount={5}
                    stepDescription="Add three sales you've made which are similar to the property you are appraising"
                  />
                  <FieldArray
                    name="comparableSales"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {values.comparableSales.map((_, i) => (
                            <ComparableSaleInputField
                              fieldName={`comparableSales[${i}]`}
                              index={i}
                              key={i}
                              onRemovePress={() =>
                                setFieldValue('comparableSales', [
                                  ...values.comparableSales.slice(0, i),
                                  ...values.comparableSales.slice(i + 1),
                                ])
                              }
                            />
                          ))}
                          {values.comparableSales.length < 3 && (
                            <AddItemRowSmallButton
                              label="Add comparable sale"
                              onClick={() => {
                                arrayHelpers.push('');
                              }}
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </PageContentContainer>
                <StepNavigationFooter
                  backHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/fees`}
                  onNextClick={submitForm}
                  nextDisabled={
                    !isValid ||
                    values.comparableSales.length == 0 || // This validation is done outside of yup due the error being icorrectly reported on individual fields
                    updateDraftPropertyAppraisalComparableSalesLoading
                  }
                  loading={updateDraftPropertyAppraisalComparableSalesLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalComparableSales;
