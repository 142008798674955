import {
  AgentBadge,
  PropertyAdditionalFeature,
  PropertyEstimatedSaleValueRange,
  PropertyIdealTimeframeOfSale,
  PropertyPreferredMethodOfSale,
  PropertyType,
  State,
} from '../graphql/generated';

export const estimatedSaleValueRangeToString: {
  [key in PropertyEstimatedSaleValueRange]: string;
} = {
  [PropertyEstimatedSaleValueRange.Under_250K]: 'Under $250k',
  [PropertyEstimatedSaleValueRange.Between_250KTo_500K]: '$250k - $500k',
  [PropertyEstimatedSaleValueRange.Between_500KTo_750K]: '$500k - $750k',
  [PropertyEstimatedSaleValueRange.Between_750KTo_1M]: '$750k - $1m',
  [PropertyEstimatedSaleValueRange.Between_1MTo_1Point_5M]: '$1m - $1.5m',
  [PropertyEstimatedSaleValueRange.Between_1Point_5MTo_2M]: '$1.5m - $2m',
  [PropertyEstimatedSaleValueRange.Between_2MTo_2Point_5M]: '$2m - $2.5m',
  [PropertyEstimatedSaleValueRange.Above_2Point_5M]: '$2.5m+',
};

export const idealTimeFrameOfSaleToString: {
  [key in PropertyIdealTimeframeOfSale]: string;
} = {
  [PropertyIdealTimeframeOfSale.ZeroToOneMonth]: '0-1 month',
  [PropertyIdealTimeframeOfSale.OneToThreeMonths]: '1-3 months',
  [PropertyIdealTimeframeOfSale.ThreeToSixMonths]: '3-6 months',
  [PropertyIdealTimeframeOfSale.SixToTwelveMonths]: '6-12 months',
  [PropertyIdealTimeframeOfSale.TwelveToTwentyFourMonths]: '12-24 months',
};

export const preferredMethodOfSaleToString: {
  [key in PropertyPreferredMethodOfSale]: string;
} = {
  [PropertyPreferredMethodOfSale.AuctionOrPrivate]: 'Any',
  [PropertyPreferredMethodOfSale.Auction]: 'Auction',
  [PropertyPreferredMethodOfSale.Private]: 'Private',
};

export const additionalFeatureToString: {
  [key in PropertyAdditionalFeature]: string;
} = {
  [PropertyAdditionalFeature.Heating]: 'Heating',
  [PropertyAdditionalFeature.Cooling]: 'Cooling',
  [PropertyAdditionalFeature.Pool]: 'Pool',
  [PropertyAdditionalFeature.Spa]: 'Spa',
  [PropertyAdditionalFeature.OutdoorArea]: 'Outdoor area',
  [PropertyAdditionalFeature.Balcony]: 'Balcony',
  [PropertyAdditionalFeature.BuiltInRobes]: 'Built in robes',
  [PropertyAdditionalFeature.Ensuite]: 'Ensuite',
  [PropertyAdditionalFeature.Study]: 'Study',
};

export const propertyTypeToString: {
  [key in PropertyType]: string;
} = {
  [PropertyType.House]: 'House',
  [PropertyType.ApartmentOrUnit]: 'Apartment / Unit',

  [PropertyType.BlockOfUnits]: 'Block of Units',
  [PropertyType.Townhouse]: 'Townhouse',
  [PropertyType.Land]: 'Land',
  [PropertyType.Acreage]: 'Acreage',
  [PropertyType.RetirementLiving]: 'Retirement Living',
};

export const badgeTypeToSaleCount: {
  [key in AgentBadge]: number;
} = {
  [AgentBadge.Diamond]: 100,
  [AgentBadge.Platinum]: 50,
  [AgentBadge.Gold]: 30,
  [AgentBadge.Silver]: 20,
  [AgentBadge.Bronze]: 10,
  [AgentBadge.Verified]: 1,
};

export const stateToShortString: {
  [key in State]: string;
} = {
  [State.Vic]: 'VIC',
  [State.Tas]: 'Tas',
  [State.Act]: 'Act',
  [State.Nsw]: 'Nsw',
  [State.Qld]: 'Qld',
  [State.Sa]: 'Sa',
  [State.Nt]: 'Nt',
  [State.Wa]: 'Wa',
};

export const DECRYPT_KEY = '7D8F68657726159AADC34DEBF656E';
