import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import dollarSignIconUrl from '../../../assets/dollar-sign.svg';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import {
  PropertyEstimatedSaleValueRange,
  PropertyIdealTimeframeOfSale,
  PropertyPreferredMethodOfSale,
  PropertyQuestionRequirement,
  useUpdatePropertySaleInformationMutation,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForOptionField } from '../components/OptionField';
import { usePrecacheForOptionSelectInputField } from '../components/OptionSelectInputField';
import PropertyQuestionRadioRowField from '../components/PropertyQuestionRadioRowField';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForPhotos } from './Photos';

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  padding: 0 16px 30px 16px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  margin: 0 0 30px 0;
`;

export const usePrecacheForSaleInformation: () => void = () => {
  usePrecacheImages([dollarSignIconUrl]);
  usePrecacheForOptionSelectInputField();
  usePrecacheForOptionField();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface FormValues {
  estimatedSaleValue?: number | string;
  estimatedSaleValueRange?: PropertyEstimatedSaleValueRange;
  idealTimeframeOfSale?: PropertyIdealTimeframeOfSale;
  preferredMethodOfSale: PropertyPreferredMethodOfSale;
}

const SaleInformation: FC = () => {
  const history = useHistory();
  const [skipToEnd, setSkipToEnd] = useState(false);

  const { draftProperty } = useCurrentDraftProperty();

  const [
    updatePropertySaleInformation,
    { loading: updatePropertySaleInformationLoading },
  ] = useUpdatePropertySaleInformationMutation();

  const [updateUserDetails] = useUpdateUserDetailsMutation();

  const backHref =
    draftProperty?.additionalFeaturesQuestionRequirement !==
    PropertyQuestionRequirement.NotRequired
      ? '/new-property/features'
      : '/new-property/details';

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftProperty) return;

      await updatePropertySaleInformation({
        variables: {
          propertyId: draftProperty.id,
          propertySaleInformation: {
            estimatedSaleValueRange: values.estimatedSaleValueRange || null,
            idealTimeframeOfSale: values.idealTimeframeOfSale || null,
            preferredMethodOfSale: values.preferredMethodOfSale,
          },
        },
      });

      const nextPage =
        '/new-property/' + (skipToEnd ? 'account-password' : 'photos');

      updateUserDetails({
        variables: {
          nextPage: nextPage,
        },
      });

      history.push(nextPage);
    },
    [draftProperty, updatePropertySaleInformation, skipToEnd],
  );

  const initialValues = !draftProperty
    ? null
    : {
        estimatedSaleValueRange:
          draftProperty.estimatedSaleValueRange || undefined,
        idealTimeframeOfSale: draftProperty.idealTimeframeOfSale || undefined,
        preferredMethodOfSale:
          draftProperty.preferredMethodOfSale ||
          PropertyPreferredMethodOfSale.AuctionOrPrivate,
      };

  usePrecacheForPhotos();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Sale Information | AgentSpot</title>
      </Helmet>
      <StepNavigationHeader
        title="Property Details"
        stepCount={6}
        stepIndex={4}
        stepName="Sale Information"
      />

      {!draftProperty ? null : (
        <Formik<FormValues> initialValues={initialValues!} onSubmit={onSubmit}>
          {({ submitForm }) => {
            return (
              <>
                <PageContentContainer>
                  <InputGroup>
                    <PropertyQuestionRadioRowField
                      fieldName="estimatedSaleValueRange"
                      label="Estimated Sale Price (Optional)"
                      box={4}
                      options={[
                        {
                          value: PropertyEstimatedSaleValueRange.Under_250K,
                          label: 'Under $250k',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Between_250KTo_500K,
                          label: '$250k - $500k',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Between_500KTo_750K,
                          label: '$500k - $750k',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Between_750KTo_1M,
                          label: '$750k - $1m',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Between_1MTo_1Point_5M,
                          label: '$1m - $1.5m',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Between_1Point_5MTo_2M,
                          label: '$1.5m - $2m',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Between_2MTo_2Point_5M,
                          label: '$2m - $2.5m',
                        },
                        {
                          value:
                            PropertyEstimatedSaleValueRange.Above_2Point_5M,
                          label: '$2.5m+',
                        },
                      ]}
                    />
                  </InputGroup>
                  <InputGroup>
                    <PropertyQuestionRadioRowField
                      fieldName="idealTimeframeOfSale"
                      label="Do you have a timeframe of sale? (Optional)"
                      box={4}
                      options={[
                        {
                          value: PropertyIdealTimeframeOfSale.ZeroToOneMonth,
                          label: '0 - 1 month',
                        },
                        {
                          value: PropertyIdealTimeframeOfSale.OneToThreeMonths,
                          label: '1 - 3 months',
                        },
                        {
                          value: PropertyIdealTimeframeOfSale.ThreeToSixMonths,
                          label: '3 - 6 months',
                        },
                        {
                          value: PropertyIdealTimeframeOfSale.SixToTwelveMonths,
                          label: '6 - 12 months',
                        },
                        {
                          value:
                            PropertyIdealTimeframeOfSale.TwelveToTwentyFourMonths,
                          label: '12 - 24 months',
                        },
                      ]}
                    />
                  </InputGroup>

                  <InputGroup>
                    <PropertyQuestionRadioRowField
                      fieldName="preferredMethodOfSale"
                      label="Choose your preferred method of sale"
                      box={4}
                      options={[
                        {
                          value: PropertyPreferredMethodOfSale.AuctionOrPrivate,
                          label: 'Any',
                        },
                        {
                          value: PropertyPreferredMethodOfSale.Private,
                          label: 'Private',
                        },
                        {
                          value: PropertyPreferredMethodOfSale.Auction,
                          label: 'Auction',
                        },
                      ]}
                    />
                  </InputGroup>
                </PageContentContainer>
                <StepNavigationFooter
                  backHref={backHref}
                  onNextClick={() => {
                    setSkipToEnd(false);
                    submitForm();
                  }}
                  onSkipClick={() => {
                    setSkipToEnd(true);
                    submitForm();
                  }}
                  nextDisabled={updatePropertySaleInformationLoading}
                  loading={updatePropertySaleInformationLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default SaleInformation;
