import { ApolloError } from '@apollo/client';
import { Formik } from 'formik';
import shuffle from 'lodash.shuffle';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ASSellerSignUpNote from '../../../components/ASSellerSignUpNote';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NextNavButton, {
  usePrecacheForNextNavButton,
} from '../../../components/NextNavButton';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { alertOnError } from '../../../constants/ErrorCodes';
import {
  CurrentDraftPropertyDocument,
  MyPropertiesDocument,
  PropertyState,
  useAgentsForPropertyLazyQuery,
  usePublishPropertyMutation,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import fullAddressOfProperty from '../../../utils/fullAddressOfProperty';
import PrivacyPolicyCheckField from '../../properties/components/PrivacyPolicyCheckField';
import AgentOptionField, {
  usePrecacheForAgentOptionField,
} from '../components/AgentOptionField';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForRequestsSent } from './RequestSent';

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  padding-bottom: 30px;
`;

const PageTitle = styled.h4`
  font-family: Poppins, Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #203553;
  text-align: center;
  padding: 16px;
  margin: 16px 0 0 0;
`;

const ExplanationLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #58687e;
  letter-spacing: 0.15px;
  text-align: center;
`;

const AddressLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 24px;
  color: #1745b0;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 11px;
`;

const AgentOptionsContainer = styled.div`
  margin: 18px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const NumberSelectAgentsLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #a6aeba;
  letter-spacing: 0.2px;
`;

const PrivacyAlert = styled.div`
  display: flex;
  justify-content: center;
  background: #d68f40;
  padding: 16px 60px;
  color: white;
  margin-top: -32px;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const PrivacyInfo = styled.div`
  margin: 0 20px 23px 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #8f9aa9;
  letter-spacing: 0.14px;
  text-align: center;
  line-height: 24px;
`;

export const usePrecacheForSelectAgents: () => void = () => {
  // Prefetch agents and agents profiles photos and agency logos
  const { draftProperty } = useCurrentDraftProperty();
  const [
    getAgentsForProperty,
    { data: agentsForPropertyData },
  ] = useAgentsForPropertyLazyQuery();

  // Fetch agents for property once draft property is loaded
  useEffect(() => {
    if (draftProperty) {
      getAgentsForProperty({ variables: { propertyId: draftProperty.id } });
    }
  }, [draftProperty]);

  usePrecacheImages([
    ...((agentsForPropertyData?.agentsForProperty
      ?.map((agent) => agent.profilePicture?.thumbnailUrl)
      .filter((url) => url != undefined) as string[]) ?? []),
    ...((agentsForPropertyData?.agentsForProperty
      ?.map((agent) => agent?.agencyLogo?.url)
      .filter((url) => url != undefined) as string[]) ?? []),
  ]);

  usePrecacheForAgentOptionField();
  usePrecacheForNextNavButton();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface FormValues {
  selectedAgents: Array<string>;
  askAgentSpotForMoreAgents: boolean;
}

const SelectAgents: FC = () => {
  const history = useHistory();

  const { draftProperty } = useCurrentDraftProperty();
  const [
    getAgentsForProperty,
    { data: agentsForPropertyData },
  ] = useAgentsForPropertyLazyQuery();

  const handleError = (error: ApolloError): void => {
    if (error.message === 'verification_pending') {
      history.push('/');
    } else if (error.message === 'no_agents_and_verification_pending') {
      history.push('/new-property/no-agents');
    } else {
      alertOnError(error);
    }
  };

  const [
    publishProperty,
    { loading: publishPropertyLoading },
  ] = usePublishPropertyMutation({
    refetchQueries: [
      { query: CurrentDraftPropertyDocument },
      { query: MyPropertiesDocument, variables: { first: 10 } },
      // 'myProperties',
    ],
    onError: handleError,
  });

  // Fetch agents for property once draft property is loaded
  useEffect(() => {
    if (draftProperty) {
      getAgentsForProperty({ variables: { propertyId: draftProperty.id } });
    }
  }, [draftProperty]);

  // Shuffle agents to prevent bias
  const randomisedAgents = useMemo(() => {
    return agentsForPropertyData?.agentsForProperty
      ? shuffle(
          agentsForPropertyData.agentsForProperty.filter(
            (agent) =>
              !!agent.profilePicture &&
              !!agent.agencyLogo &&
              !!agent.agencyName,
          ),
        )
      : null;
  }, [agentsForPropertyData?.agentsForProperty]);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftProperty) return;

      await publishProperty({
        variables: {
          propertyId: draftProperty.id,
          selectedAgents: values.selectedAgents,
          askAgentSpotForMoreAgents: values.askAgentSpotForMoreAgents,
        },
      }).then((result) => {
        if (result?.data) {
          history.push('/new-property/request-sent');
        }
      });
    },
    [draftProperty, publishProperty],
  );

  useEffect(() => {
    if (
      draftProperty &&
      draftProperty.state == PropertyState.Draft &&
      agentsForPropertyData &&
      agentsForPropertyData.agentsForProperty.length == 0
    ) {
      publishProperty({
        variables: {
          propertyId: draftProperty.id,
          selectedAgents: [],
          askAgentSpotForMoreAgents: false,
        },
      }).then((result) => {
        if (result?.data) {
          history.push('/new-property/no-agents');
        }
      });
    }
  }, [draftProperty, agentsForPropertyData]);

  const validateFormValues = useCallback(
    (values: FormValues) => {
      const errors: { [key in keyof FormValues]?: string } = {};

      if (
        values.selectedAgents.length == 0 &&
        (!agentsForPropertyData ||
          agentsForPropertyData.agentsForProperty.length > 0) &&
        !values.askAgentSpotForMoreAgents
      ) {
        errors.selectedAgents = 'Required';
      }

      return errors;
    },
    [draftProperty, agentsForPropertyData, publishProperty],
  );

  const initialValues = {
    selectedAgents: [],
    askAgentSpotForMoreAgents: false,
  } as FormValues;

  const address = fullAddressOfProperty(draftProperty);

  usePrecacheForRequestsSent();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Select Agents | AgentSpot</title>
      </Helmet>
      <PageTitle>Select Agents</PageTitle>
      {!draftProperty ||
      !agentsForPropertyData ||
      agentsForPropertyData.agentsForProperty.length === 0 ? null : (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validate={validateFormValues}
          validateOnMount
          validateOnChange>
          {({ submitForm, values, isValid }) => {
            const submitDisabled = publishPropertyLoading || !isValid;

            return (
              <>
                <PageContentContainer>
                  <ExplanationLabel>
                    Select agents to request appraisals
                  </ExplanationLabel>
                  <AddressLabel>{address}</AddressLabel>
                </PageContentContainer>
                <PrivacyAlert>Your contact details remain private</PrivacyAlert>
                <PageContentContainer>
                  <PrivacyInfo>
                    Don&apos;t worry, your contact details will remain private
                    until you choose to be contacted.
                  </PrivacyInfo>
                  <AgentOptionsContainer>
                    {randomisedAgents?.map((agent) => (
                      <AgentOptionField
                        fieldName="selectedAgents"
                        agent={agent}
                        key={agent.id}
                        showStats={false}
                      />
                    ))}
                  </AgentOptionsContainer>
                  <ASSellerSignUpNote />
                </PageContentContainer>
                {agentsForPropertyData.agentsForProperty.length === 1 && (
                  <PrivacyPolicyCheckField name="askAgentSpotForMoreAgents" />
                )}
                <NavigationFooter
                  leftSideComponent={
                    <NumberSelectAgentsLabel>
                      {values.selectedAgents.length} Selected
                    </NumberSelectAgentsLabel>
                  }
                  rightSideComponent={
                    <NextNavButton
                      label="Request Appraisals"
                      nextDisabled={submitDisabled}
                      loading={publishPropertyLoading}
                      onNextClick={submitForm}
                    />
                  }
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default SelectAgents;
