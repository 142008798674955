import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import client from '../../../client';
import ASActionButton from '../../../components/ASActionButton';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import {
  PropertyCompleteType,
  PropertyWithAppraisalsFragment,
  PropertyWithAppraisalsFragmentDoc,
  usePropertyQuery,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import AgentOptionField from '../../new-property/components/AgentOptionField';
import SaleStatusNavigationHeader from '../components/SaleStatusNavigationHeader';
import { usePrecacheForBankDetails } from './BankDetails';

const Question = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(16)};
  color: #58687e;
  letter-spacing: 0.15px;
  text-align: center;
  margin: 16px 0 20px 0;
  padding: 0;
`;

interface FormValues {
  selectedAgent: string | null;
}

interface ExpectedPathParams {
  propertyId?: string;
}

export const usePrecacheForSelectSaleAgent: (propertyId: string) => void = (
  propertyId,
) => {
  const { data: propertyData } = usePropertyQuery({
    variables: { id: typeof propertyId === 'string' ? propertyId : '' },
  });

  usePrecacheImages([
    ...((propertyData?.property.propertyAppraisals
      ?.map((appraisal) => appraisal.agent.profilePicture?.thumbnailUrl)
      .filter((url) => url != undefined) as string[]) ?? []),
    ...((propertyData?.property?.propertyAppraisals
      ?.map((appraisal) => appraisal.agent.agencyLogo?.url)
      .filter((url) => url != undefined) as string[]) ?? []),
  ]);

  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

const SelectSaleAgent: FC = () => {
  const history = useHistory();
  const { propertyId } = useParams<ExpectedPathParams>();

  const cachedProperty =
    client.readFragment<PropertyWithAppraisalsFragment>({
      id: `Property:${propertyId}`,
      fragment: PropertyWithAppraisalsFragmentDoc,
      fragmentName: 'PropertyWithAppraisals',
    }) ?? undefined;

  const { data: propertyData } = usePropertyQuery({
    variables: { id: typeof propertyId === 'string' ? propertyId : '' },
  });

  const property: PropertyWithAppraisalsFragment | undefined =
    propertyData?.property ?? cachedProperty;

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!property) return;

      history.push(
        `/property/${propertyId}/sale-status/sold/agent/${values.selectedAgent}/claim`,
      );
    },
    [property],
  );

  const validateFormValues = useCallback(
    (values: FormValues) => {
      const errors: { [key in keyof FormValues]?: string } = {};

      if (
        !values.selectedAgent ||
        (values.selectedAgent &&
          (!property || property.propertyAppraisals.length === 0))
      ) {
        errors.selectedAgent = 'Required';
      }

      return errors;
    },
    [property],
  );

  const initialValues = {
    selectedAgent: null,
  } as FormValues;

  if (
    property &&
    property.completeType === PropertyCompleteType.SoldUnconditionally
  ) {
    return <Redirect to={`/property/${propertyId}`} />;
  }

  usePrecacheForBankDetails();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Select Agent | AgentSpot</title>
      </Helmet>
      {!property ||
      !property.propertyAppraisals ||
      property.propertyAppraisals.length === 0 ? null : (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validate={validateFormValues}
          validateOnMount
          validateOnChange>
          {({ submitForm, isValid }) => {
            const submitDisabled = !isValid;

            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <SaleStatusNavigationHeader
                    backHref={`/property/${propertyId}/sale-status`}
                    pageTitle="Select Agent"
                  />
                  <Question>Which agent did you engage with?</Question>
                  {property.propertyAppraisals?.map((appraisal) => (
                    <AgentOptionField
                      fieldName="selectedAgent"
                      agent={appraisal.agent}
                      key={appraisal.agent.id}
                      type="radio"
                      showStats={false}
                    />
                  ))}
                </StepPageTemplate.ContentContainer>
                <NavigationFooter
                  rightSideComponent={
                    <ASActionButton
                      disabled={submitDisabled}
                      visiblyDisabled={submitDisabled}
                      onClick={submitForm}>
                      Continue
                    </ASActionButton>
                  }
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default SelectSaleAgent;
