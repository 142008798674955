import { Field, useField } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import AgentProfilePhoto from '../../../components/AgentProfilePhoto';
import theme from '../../../constants/theme';
import { AgentBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { ReactComponent as DotSeparatorIcon } from '../assets/dot-separator.svg';

const Container = styled.label<{ checked?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 30px 20px;
  margin: 0 0 12px 0;

  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 0px;

  ${(props) =>
    props.checked
      ? `
    border: 1px solid #1745B0;
    padding: 29px 19px;
    `
      : ''}

  position: relative;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const NameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.25px;
  margin-right: 20px;
`;

const AgencyNameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  margin: 0;
`;

const StatsLabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
`;

const StatsLabel = styled.span`
  font-family: Inter, Arial, sans-serif;
  font-size: 11px;
  color: #8f9aa9;
  letter-spacing: 0.14px;
  margin: 0;
  text-transform: capitalize;
`;

const DotSeparator = styled(DotSeparatorIcon)`
  margin: 0 6px;
  width: 3px;
  height: 3px;
`;

const HiddenField = styled(Field).attrs(({ type }) => ({
  type,
}))`
  display: none;
`;

export const usePrecacheForAgentOptionField: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

export interface AgentOptionFieldProps {
  fieldName: string;
  type?: string;
  agent: AgentBaseFragment;
  showStats?: boolean;
}

const AgentOptionField: FC<AgentOptionFieldProps> = (props) => {
  const { fieldName, agent, type = 'checkbox', showStats = true } = props;

  const [{ checked }] = useField({
    name: fieldName,
    value: agent.id,
    type: type,
  });

  return (
    <Container checked={checked}>
      <HiddenField name={fieldName} value={agent.id} type={type} />
      <AgentProfilePhoto agent={agent} showAgencyLogo />
      <LabelsContainer>
        <NameLabel>{agent.name}</NameLabel>
        <AgencyNameLabel>{agent.agencyName}</AgencyNameLabel>
        {showStats && (
          <StatsLabelContainer>
            <StatsLabel>
              {agent.numberOfEngagements} Current Listings
            </StatsLabel>
            <DotSeparator />
            <StatsLabel>{agent.badge?.toLocaleLowerCase()} Member</StatsLabel>
          </StatsLabelContainer>
        )}
      </LabelsContainer>

      <CheckBox checked={checked ?? false} />
    </Container>
  );
};

export default AgentOptionField;
